import React, { MouseEvent } from 'react';
import { Theme, withStyles, WithStyles } from '@material-ui/core';
import Page, { Panel } from '../Page';
import { Value } from 'slate';
import { Editor } from 'slate-react';
import graphql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { List } from 'immutable';
import EditPageDetails from './dialogs/EditPageDetails';
import Dialog from '@material-ui/core/Dialog';
import { Snackbar, SnackbarVariant, SaveButton } from 'b6a-components/dist';
import * as Sentry from '@sentry/browser';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import Label from '@material-ui/core/InputLabel';
import moment from 'moment';

const SAVE_NEW_VERSION = graphql`
  mutation savePageVersion($client: String!, $route: String!, $panels: [PanelInput!]!) {
    saveVersion(clientSlug: $client, route: $route, content: $panels) {
      id
      pageVersions {
        id
        created
      }
    }
  }
`;

const PUBLISH_VERSION = graphql`
  mutation publishPageVersion($client: String!, $route: String!, $version: ID!) {
    publishVersion(clientSlug: $client, route: $route, version: $version) {
      route
    }
  }
`;

export interface DialogProps {
  onClose: (type: string, values?: any) => void;
  editor?: Editor;
}

const styles = (theme: Theme) => ({
  paper: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    borderRadius: 8,
    zIndex: 1000,
  },
  buttonIcons: {
    padding: theme.spacing.unit * 0.75,
    marginTop: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit / 2,
    minWidth: 0,
  },
  listItem: {
    display: 'list-item',
  },
  gridRoot: {
    flexGrow: 1,
  },
  paperEditButton: {
    marginLeft: 7,
  },
});

interface PageEditorProps {
  client: string;
  route: string;
  title: string;
  menuBarTitle: string;
  menuBarIcon: string;
  inMenuBar: boolean;
  pageId: string;
  version: string;
  onVersionChange: (newVersion: string) => void;
  pageVersions: {
    id: string;
    created: string;
  }[];
  panels: {
    id: string;
    order: number;
    size: number;
    panelType: string;
    document: string;
  }[];
}

interface PageEditorState {
  panels: List<Panel>;
  actions: List<List<Panel>>;
  redos: List<List<Panel>>;
  dialogOpen: boolean;
  dialogBody: React.ReactElement<DialogProps> | null;
}

class PageEditor extends React.Component<
  PageEditorProps & WithStyles<typeof styles>,
  PageEditorState
> {
  constructor(props: PageEditorProps & WithStyles<typeof styles>) {
    super(props);

    const panels = List(
      props.panels.map((panel, index) => {
        return {
          ...panel,
          document: Value.fromJSON(JSON.parse(panel.document)),
          editable: true,
          callback: this.reducer(index),
        } as Panel;
      })
    );

    this.state = {
      panels,
      dialogOpen: false,
      dialogBody: null,
      actions: List.of(panels),
      redos: List(),
    };
  }

  convertPanels(): Panel[] {
    const { panels } = this.state;

    return panels.toJS().map((panel: Panel, index: number) => {
      const temp = {
        ...panel,
        order: index,
        document: JSON.stringify(panel.document.toJSON()),
      };

      delete temp.editable;
      // @ts-ignore
      delete temp.__typename;

      return temp;
    });
  }

  openDialog(dialog: React.ReactElement<DialogProps>) {
    this.setState({ dialogOpen: true, dialogBody: dialog });
  }

  closeDialog() {
    this.setState({ dialogOpen: false, dialogBody: null });
  }

  onClickEditDetails = (event: MouseEvent<HTMLElement>) => {
    const { title, menuBarTitle, menuBarIcon, route, client, pageId } = this.props;
    event.preventDefault();

    this.openDialog(
      <EditPageDetails
        handleClose={this.closeDialog.bind(this)}
        onClose={this.closeDialog.bind(this)}
        title={title}
        menuBarTitle={menuBarTitle}
        menuBarIcon={menuBarIcon}
        route={route}
        clientSlug={client}
        pageId={pageId}
      />
    );
  };

  reducer(index: number) {
    return (action: { type: string; value: any }) => {
      let newActions: List<List<Panel>> | undefined = undefined;
      let redos: List<List<Panel>> | undefined = undefined;

      const newState = ((): List<Panel> | undefined => {
        const { panels } = this.state;

        switch (action.type) {
          case 'onchange':
            return panels.update(index, panel => {
              // If we have the same value as before, don't push a
              // new change to actions
              if (
                JSON.stringify(action.value.toJSON()) === JSON.stringify(panel.document.toJSON())
              ) {
                newActions = this.state.actions;
              }
              return {
                ...panel,
                document: action.value,
              };
            });
          case 'edit-paper': {
            this.closeDialog();
            return panels.update(index, panel => {
              Sentry.addBreadcrumb({
                category: 'PageEditor.reducer.paper-edit',
                data: {
                  index,
                  action,
                },
                level: 'debug' as Sentry.Severity,
              });

              return {
                ...panel,
                ...action.value,
              };
            });
          }
          case 'insert-before':
          case 'insert-after': {
            let offset = 0;
            if (action.type === 'insert-after') offset = 1;

            const oldPanel = panels.get(index);

            Sentry.addBreadcrumb({
              category: 'PageEditor.reducer.insert',
              data: {
                action,
              },
              level: 'debug' as Sentry.Severity,
            });

            return panels.insert(index + offset, { ...oldPanel, id: '0' }).map(
              (panel, index) =>
                // @ts-ignore
                ({
                  ...panel,
                  order: index,
                  document: panel ? Value.fromJSON(panel.document.toJSON()) : Value.fromJS({}),
                  callback: this.reducer(index as number),
                } as Panel)
            ) as List<Panel>;
          }
          case 'delete': {
            Sentry.addBreadcrumb({
              category: 'PageEditor.reducer.panel-delete',
              data: {
                action,
              },
              level: 'debug' as Sentry.Severity,
            });
            return panels.delete(index).map(
              (panel, index) =>
                // @ts-ignore
                ({
                  ...panel,
                  order: index,
                  document: panel ? Value.fromJSON(panel.document.toJSON()) : Value.fromJS({}),
                  callback: this.reducer(index as number),
                } as Panel)
            ) as List<Panel>;
          }
          case 'undo': {
            Sentry.addBreadcrumb({
              category: 'PageEditor.reducer.undo',
              data: {
                action,
              },
              level: 'debug' as Sentry.Severity,
            });
            if (this.state.actions.size > 1) {
              redos = this.state.redos.push(this.state.actions.last());
              newActions = this.state.actions.pop();
              return newActions.last();
            }
            return undefined;
          }
          case 'redo': {
            Sentry.addBreadcrumb({
              category: 'PageEditor.reducer.redo',
              data: {
                action,
              },
              level: 'debug' as Sentry.Severity,
            });
            newActions = this.state.actions.push(this.state.redos.last());
            redos = this.state.redos.pop();
            return this.state.redos.last();
          }
          // Handle Move Forward/Backwards Case
          case 'move-forward':
          case 'move-backward': {
            Sentry.addBreadcrumb({
              category: 'PageEditor.reducer.move-forward',
              data: {
                action,
              },
              level: 'debug' as Sentry.Severity,
            });

            let newIndex = index;

            // Do nothing for the last panel
            if (action.type === 'move-forward') {
              if (index + 1 === panels.size) return undefined;
              newIndex += 1;
            } else if (index === 0) return undefined;

            const movingPanel = panels.get(newIndex);

            return panels
              .delete(newIndex)
              .insert(newIndex - 1, movingPanel)
              .map(
                (panel, index) =>
                  // @ts-ignore
                  ({
                    ...panel,
                    order: index,
                    document: panel ? Value.fromJSON(panel.document.toJSON()) : Value.fromJS({}),
                    callback: this.reducer(index as number),
                  } as Panel)
              ) as List<Panel>;
          }
          default:
            return undefined;
        }
      })();

      if (newState) {
        this.setState({
          panels: newState,
          actions: newActions || this.state.actions.push(newState),
          redos: redos || List(List.of(newState)),
        });
      }
    };
  }

  renderSaveButton() {
    const { client, route } = this.props;

    return (
      <Mutation
        onCompleted={(data: { saveVersion: { pageVersions: { id: string }[] } }) => {
          setTimeout(
            () =>
              this.props.onVersionChange(
                data.saveVersion.pageVersions
                  .map(v => v.id)
                  .reduce((a, b) => `${Math.max(parseInt(a, 10), parseInt(b, 10))}`)
              ),
            1000
          );
        }}
        mutation={SAVE_NEW_VERSION}
      >
        {(savePage, { data, error, loading }) => (
          <>
            {data && <Snackbar message={'Successfully Saved!'} variant={SnackbarVariant.SUCCESS} />}
            {error && (
              <Snackbar
                message={'Unfortunately there was an error.'}
                variant={SnackbarVariant.ERROR}
              />
            )}
            <SaveButton
              id="saveButton"
              loading={loading}
              onClick={() =>
                savePage({
                  variables: { client, route, panels: this.convertPanels() },
                })
              }
            >
              Save Version
            </SaveButton>
          </>
        )}
      </Mutation>
    );
  }

  renderPublishButton() {
    const { client, route, version } = this.props;

    return (
      <Mutation
        onCompleted={() => {
          setTimeout(() => this.props.onVersionChange('published'), 1000);
        }}
        mutation={PUBLISH_VERSION}
      >
        {(publishVersion, { data, error, loading }) => (
          <>
            {data && (
              <Snackbar
                message={'Version Published Successfully'}
                variant={SnackbarVariant.SUCCESS}
              />
            )}
            {error && (
              <Snackbar
                message={'Unfortunately there was an error.'}
                variant={SnackbarVariant.ERROR}
              />
            )}
            <SaveButton
              id="publishButton"
              loading={loading}
              color="secondary"
              disabled={version === 'published'}
              onClick={() =>
                publishVersion({
                  variables: { client, route, version },
                })
              }
            >
              Publish
            </SaveButton>
          </>
        )}
      </Mutation>
    );
  }

  // Render the editor.
  render() {
    const { client, route, classes, version, pageVersions } = this.props;
    const { panels } = this.state;

    const dateFormat = 'ddd, MMM Do YYYY, h:mm:ss a';

    let v = version;

    if (version === 'latest') {
      v = pageVersions
        .map(v => v.id)
        .reduce((a, b) => `${Math.max(parseInt(a, 10), parseInt(b, 10))}`);
    }

    return (
      <>
        <Paper
          className={classes.paper}
          elevation={1}
          style={{
            top: 24,
          }}
        >
          {this.renderSaveButton()}
          <Button id="clientViewButton" href={`/${client}/${route}?v=${v}`} target="_blank">
            Client View
          </Button>
          <Button
            id="undoButton"
            onClick={() => this.reducer(-1)({ type: 'undo', value: undefined })}
            disabled={this.state.actions.size < 2}
          >
            Undo
          </Button>
          <Button
            id="redoButton"
            onClick={() => this.reducer(-1)({ type: 'redo', value: undefined })}
            disabled={this.state.redos.size < 2}
          >
            Redo
          </Button>
          <Button id="editDetailsButton" onClick={this.onClickEditDetails.bind(this)}>
            Edit Details
          </Button>
          <Label>Version:</Label>&nbsp;
          <Select value={v} onChange={event => this.props.onVersionChange(event.target.value)}>
            <MenuItem value={'published'}>Published</MenuItem>
            {this.props.pageVersions.map(pageVersion => (
              <MenuItem value={pageVersion.id} key={pageVersion.id}>
                {moment(pageVersion.created).format(dateFormat)}
              </MenuItem>
            ))}
          </Select>
          {this.renderPublishButton()}
        </Paper>
        <Page
          panels={panels.toArray().map((panel, index) => ({
            ...panel,
            order: index,
            testId: `panel-${panel.order}`,
          }))}
          client={client}
          dialog={this.openDialog.bind(this)}
          dialogClose={this.closeDialog.bind(this)}
        />
        <Dialog
          open={this.state.dialogOpen}
          fullWidth={true}
          maxWidth="sm"
          onClose={() => this.closeDialog()}
          aria-labelledby="form-dialog-title"
        >
          {this.state.dialogBody || <></>}
        </Dialog>
      </>
    );
  }
}

// @ts-ignore
export default withStyles(styles)(PageEditor);
