import React from 'react';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Theme } from '@material-ui/core';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import { ApolloConsumer } from 'react-apollo';

const styles = (theme: Theme) => ({});

class Logout extends React.Component<WithStyles<typeof styles & RouteComponentProps>> {
  componentWillMount(): void {
    localStorage.removeItem('token');
  }

  render() {
    return (
      <ApolloConsumer>
        {client => {
          client.clearStore();
          return <Redirect to={'/login'} />;
        }}
      </ApolloConsumer>
    );
  }
}

// @ts-ignore
export default withStyles(styles)(Logout);
