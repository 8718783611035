import React, { MouseEvent } from 'react';
import { Menu, MenuItem, Theme, withStyles, WithStyles } from '@material-ui/core';
import Typography from '@material-ui/core/Typography';
import { Editor, RenderMarkProps } from 'slate-react';
import { Block, Document, Editor as CoreEditor, Operation, Value } from 'slate';
import AddImageIcon from '@material-ui/icons/AddPhotoAlternateRounded';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import BoldIcon from '@material-ui/icons/FormatBoldRounded';
import ItalicIcon from '@material-ui/icons/FormatItalicRounded';
import UnderlinedIcon from '@material-ui/icons/FormatUnderlinedRounded';
import TitleIcon from '@material-ui/icons/LooksOneRounded';
import LinkIcon from '@material-ui/icons/LinkRounded';
import SubTitleIcon from '@material-ui/icons/LooksTwoRounded';
import BulletListIcon from '@material-ui/icons/FormatListBulletedRounded';
import NumberListIcon from '@material-ui/icons/FormatListNumberedRounded';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import TableImageIcon from '@material-ui/icons/TableChartRounded';
import AddVideoIcon from '@material-ui/icons/VideocamRounded';
import YouTube from 'react-youtube';
import { PanelTable } from 'b6a-components/dist';
import AddImageDialog from '../PageEditor/dialogs/AddImageDialog';
import SetTableId, { GET_TABLE_DATA, GetTableData } from '../PageEditor/dialogs/SetTableId';
import * as Immutable from 'immutable';
import Fab from '@material-ui/core/Fab';
import EditPaperDialog from '../PageEditor/dialogs/EditPaper';
import { ApolloConsumer } from 'react-apollo';
import { DialogProps } from '../PageEditor';
import AddVideoDialog from '../PageEditor/dialogs/AddVideoDialog';
import HyperlinkDialog from '../PageEditor/dialogs/HyperlinkDialog';
import { Link as RouterLink } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const DEFAULT_NODE = 'paragraph';

type EditorOnChange = { operations: Immutable.List<Operation>; value: Value };

const styles = (theme: Theme) => ({
  paper: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    paddingLeft: theme.spacing.unit,
    paddingRight: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
    borderRadius: 8,
    zIndex: 1000,
  },
  buttonIcons: {
    padding: theme.spacing.unit * 0.75,
    marginTop: theme.spacing.unit / 2,
    marginBottom: theme.spacing.unit / 2,
    minWidth: 0,
  },
  listItem: {
    display: 'list-item',
  },
  gridRoot: {
    flexGrow: 1,
  },
  paperEditButton: {
    marginLeft: 7,
  },
  fab: {
    position: 'absolute' as 'absolute',
    bottom: 10,
    right: 10,
    color: theme.palette.error.dark,
  },
  imgWrapper: {
    position: 'relative' as 'relative',
  },
});

interface B6AEditorProps extends WithStyles<typeof styles> {
  readOnly: boolean;
  value: Value;
  callback?: (payload: { type: string; value?: any }) => void;
  dialog?: (dialog: React.ReactElement<DialogProps>) => void;
  dialogClose?: () => void;
  clientSlug: string;
  panelProps: {
    color?: string;
    icon?: string;
    panelType: string;
    size: number;
    imageUrl?: string;
    title?: string;
    stat?: string;
    value?: string;
    testId: string;
  };
}

interface B6AEditorState {
  anchorEl: null | HTMLElement;
}

class B6AEditor extends React.Component<B6AEditorProps, B6AEditorState> {
  editor: Editor | undefined = undefined;

  // Set the initial value when the app is first constructed.
  constructor(props: B6AEditorProps) {
    super(props);

    this.state = {
      anchorEl: null,
    };
  }

  // Store a reference to the `editor`.
  ref = (editor: Editor) => {
    this.editor = editor;
  };

  // On change, update the app's React state with the new editor value.
  onChange = ({ value }: EditorOnChange) => {
    const { callback } = this.props;
    callback && callback({ value, type: 'onchange' });
  };

  onClickImage = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.props.dialog &&
      this.props.dialog(<AddImageDialog onClose={this.handleDialogClose} editor={this.editor} />);
  };

  clickedAddLink = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.props.dialog &&
      this.props.dialog(
        <HyperlinkDialog
          client={this.props.clientSlug}
          onClose={this.handleDialogClose}
          editor={this.editor}
        />
      );
  };

  onClickAddVideo = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.props.dialog &&
      this.props.dialog(<AddVideoDialog onClose={this.handleDialogClose} editor={this.editor} />);
  };

  onAddTable = (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    this.editor &&
      this.editor.insertBlock({
        type: 'table',
        data: {},
      });
  };

  onClickSetSheet = (node: Block) => (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();
    this.props.dialog &&
      this.props.dialog(
        <SetTableId onClose={this.handleDialogClose} editor={this.editor} node={node} />
      );
  };

  deleteNode = (node: Block) => (event: MouseEvent<HTMLElement>) => {
    event.preventDefault();

    this.editor && this.editor.removeNodeByPath(this.editor.value.document.getPath(node.key));
  };

  createLink = (to: string) => (props: any) => <RouterLink to={to} {...props} />;

  // Add a `renderNode` method to render a `CodeNode` for code blocks.
  renderNode = (props: any, editor: any, next: any) => {
    const { attributes, node, children, key } = props;
    const { classes } = this.props;
    const { testId } = this.props.panelProps;
    const itemId = `node-${testId}-${node.type}-${node.key}`;

    switch (node.type) {
      case 'paragraph':
        return (
          <Typography id={itemId} component="p" {...attributes}>
            {children}
          </Typography>
        );
      case 'block-quote':
        return (
          <Typography id={itemId} variant="blockquote" {...attributes}>
            {children}
          </Typography>
        );
      case 'heading-one':
        return (
          <Typography id={itemId} variant="h5" {...attributes}>
            {children}
          </Typography>
        );
      case 'heading-two':
        return (
          <Typography id={itemId} variant="h6" {...attributes}>
            {children}
          </Typography>
        );
      case 'bulleted-list':
        return (
          <Typography id={itemId} component="ul" key={key} {...attributes}>
            {children}
          </Typography>
        );
      case 'list-item':
        return (
          <Typography
            component="li"
            key={key}
            id={itemId}
            className={this.props.classes.listItem}
            {...attributes}
          >
            {children}
          </Typography>
        );
      case 'numbered-list':
        return (
          <Typography id={itemId} component="ol" {...attributes}>
            {children}
          </Typography>
        );
      case 'link': {
        const { data } = node;
        const href: string | undefined = data.get('href');
        if (!href) return <></>;

        if (href.startsWith('/')) {
          return (
            <Link id={itemId} {...attributes} component={this.createLink(href)}>
              {children}
            </Link>
          );
        }
        return (
          <Link {...attributes} href={href} target="_blank">
            {children}
          </Link>
        );
      }
      case 'image': {
        const src = node.data.get('src');
        return (
          <div className={classes.imgWrapper} {...attributes}>
            {!this.props.readOnly && (
              <Fab aria-label="Delete" className={classes.fab} onClick={this.deleteNode(node)}>
                <DeleteIcon />
              </Fab>
            )}
            <img id={itemId} src={src} alt="image" {...node.data.get('attrs')} />
          </div>
        );
      }
      case 'video': {
        const src = node.data.get('src');
        const height = (() => {
          switch (this.props.panelProps.size) {
            // 3 -> 1/4
            case 3:
              return '225';
            // 4 -> 1/3
            case 4:
              return '300';
            // 6 -> 1/2
            case 6:
              return '450';
            // 8 -> 2/3
            case 8:
              return '700';
            // 12 -> Full
            case 12:
              return '900';
          }
        })();

        return (
          <div className={classes.imgWrapper}>
            {!this.props.readOnly && (
              <Fab aria-label="Delete" className={classes.fab} onClick={this.deleteNode(node)}>
                <DeleteIcon />
              </Fab>
            )}
            <YouTube
              {...attributes}
              id={itemId}
              videoId={src}
              opts={{
                height,
                width: '100%',
                playerVars: {
                  modestbranding: 1,
                },
              }}
            />
          </div>
        );
      }
      case 'grid': {
        return (
          <Grid id={itemId} container className={classes.gridRoot} spacing={16} {...attributes}>
            {children}
          </Grid>
        );
      }
      case 'table': {
        const tableData = node.data.get('tableData');
        const tableId = node.data.get('tableId');

        return (
          <div {...attributes} id={itemId}>
            {!this.props.readOnly && (
              <div>
                <Button variant="contained" onClick={this.onClickSetSheet(node).bind(this)}>
                  Set Sheet
                </Button>

                <ApolloConsumer>
                  {client => (
                    <Button
                      variant="contained"
                      onClick={async () => {
                        const result: GetTableData = await client.query({
                          query: GET_TABLE_DATA,
                          variables: { tableId },
                          fetchPolicy: 'no-cache',
                        });
                        const newNode = node.setIn(
                          ['data', 'tableData'],
                          result.data.table
                        ) as Block;
                        this.editor && this.editor.replaceNodeByKey(node.key, newNode);
                      }}
                    >
                      Refresh
                    </Button>
                  )}
                </ApolloConsumer>
              </div>
            )}

            <div className={classes.imgWrapper}>
              {!this.props.readOnly && (
                <Fab aria-label="Delete" className={classes.fab} onClick={this.deleteNode(node)}>
                  <DeleteIcon />
                </Fab>
              )}
              {tableData && <PanelTable {...node.data.get('attrs')} sheets={tableData.sheets} />}
            </div>
          </div>
        );
      }
      default:
        return next();
    }
  };

  renderBlockButton = (type: string, icon: React.ReactNode) => {
    // let isActive = this.hasBlock(type);

    return (
      <Button
        id={`${this.props.panelProps.testId}-block-${type}`}
        data-testid={`${this.props.panelProps.testId}-block-${type}`}
        className={this.props.classes.buttonIcons}
        onMouseDown={event => this.onClickBlock(event, type)}
      >
        {icon}
      </Button>
    );
  };

  renderMarkButton = (type: string, icon: React.ReactNode) => {
    // const isActive = this.hasMark(type);

    return (
      <Button
        id={`${this.props.panelProps.testId}-mark-${type}`}
        data-testid={`${this.props.panelProps.testId}-mark-${type}`}
        className={this.props.classes.buttonIcons}
        onClick={event => this.onClickMark(event, type)}
      >
        {icon}
      </Button>
    );
  };

  handleDialogClose = (type: string, values?: any) => {
    if (values !== undefined && this.props.callback) {
      this.props.callback({ type, value: values });
    } else if (type === 'cancel' && this.props.dialogClose) {
      this.props.dialogClose();
    } else {
      this.props.dialogClose && setTimeout(this.props.dialogClose, 0);
    }
  };

  handlePanelMenuClose = (action: string) => {
    return () => {
      if (action === 'edit') {
        this.props.dialog &&
          this.props.dialog(
            <EditPaperDialog
              icon={this.props.panelProps.icon}
              color={this.props.panelProps.color}
              panelType={this.props.panelProps.panelType}
              size={this.props.panelProps.size}
              imageUrl={this.props.panelProps.imageUrl}
              title={this.props.panelProps.title}
              stat={this.props.panelProps.stat}
              value={this.props.panelProps.value}
              onClose={this.handleDialogClose.bind(this)}
              editor={this.editor}
            />
          );
      } else if (this.props.callback) {
        if (action === 'delete') {
          this.props.callback({ type: 'delete' });
        } else if (action === 'add-before') {
          this.props.callback({ type: 'insert-before' });
        } else if (action === 'add-after') {
          this.props.callback({ type: 'insert-after' });
        } else if (action === 'move-forward') {
          this.props.callback({ type: 'move-forward' });
        } else if (action === 'move-backward') {
          this.props.callback({ type: 'move-backward' });
        }
      }
      window.setTimeout(() => this.setState({ anchorEl: null }), 0);
    };
  };

  hasBlock = (type: string) => {
    const { value } = this.props;
    return value.blocks.some(node => (node && node.type) === type);
  };

  onClickMark = (event: MouseEvent, type: string) => {
    event.preventDefault();
    this.editor && this.editor.toggleMark(type);
  };

  onClickBlock = (event: MouseEvent, type: string) => {
    event.preventDefault();

    if (this.editor === undefined) return;

    const { value } = this.editor;
    const { document } = value;

    // Handle everything but list buttons.
    if (type !== 'bulleted-list' && type !== 'numbered-list') {
      const isActive = this.hasBlock(type);
      const isList = this.hasBlock('list-item');

      if (isList) {
        this.editor
          .setBlocks(isActive ? DEFAULT_NODE : type)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else {
        this.editor.setBlocks(isActive ? DEFAULT_NODE : type);
      }
    } else {
      // Handle the extra wrapping required for list buttons.
      const isList = this.hasBlock('list-item');
      const isType = value.blocks.some(block => {
        if (!block) return false;
        return !!document.getClosest(block.key, parent => (parent as Document).type === type);
      });

      if (isList && isType) {
        this.editor
          .setBlocks(DEFAULT_NODE)
          .unwrapBlock('bulleted-list')
          .unwrapBlock('numbered-list');
      } else if (isList) {
        this.editor
          .unwrapBlock(type === 'bulleted-list' ? 'numbered-list' : 'bulleted-list')
          .wrapBlock(type);
      } else {
        this.editor.setBlocks('list-item').wrapBlock(type);
      }
    }
  };

  renderMark = (props: RenderMarkProps, editor: CoreEditor, next: () => any) => {
    const { children, mark, attributes } = props;

    switch (mark.type) {
      case 'bold':
        return <strong {...attributes}>{children}</strong>;
      case 'italic':
        return <em {...attributes}>{children}</em>;
      case 'underlined':
        return <u {...attributes}>{children}</u>;
      default:
        return next();
    }
  };

  // Render the editor.
  render() {
    const { classes, readOnly } = this.props;
    const { anchorEl } = this.state;

    return (
      <>
        {!readOnly && (
          <>
            <Paper className={classes.paper} elevation={3}>
              {this.renderMarkButton('bold', <BoldIcon />)}
              {this.renderMarkButton('italic', <ItalicIcon />)}
              {this.renderMarkButton('underlined', <UnderlinedIcon />)}
              {this.renderBlockButton('heading-one', <TitleIcon />)}
              {this.renderBlockButton('heading-two', <SubTitleIcon />)}
              {this.renderBlockButton('bulleted-list', <BulletListIcon />)}
              {this.renderBlockButton('numbered-list', <NumberListIcon />)}

              <Button
                id={`${this.props.panelProps.testId}-addImageButton`}
                data-testid={`${this.props.panelProps.testId}-addImageButton`}
                className={this.props.classes.buttonIcons}
                onClick={this.onClickImage}
              >
                <AddImageIcon />
              </Button>

              <Button
                data-testid={`${this.props.panelProps.testId}-addVideoButton`}
                id={`${this.props.panelProps.testId}-addVideoButton`}
                className={this.props.classes.buttonIcons}
                onClick={this.onClickAddVideo}
              >
                <AddVideoIcon />
              </Button>

              <Button
                data-testid={`${this.props.panelProps.testId}-addLinkButton`}
                id={`${this.props.panelProps.testId}-addLinkButton`}
                className={this.props.classes.buttonIcons}
                onClick={this.clickedAddLink}
              >
                <LinkIcon />
              </Button>

              <Button
                data-testid={`${this.props.panelProps.testId}-addTableButton`}
                id={`${this.props.panelProps.testId}-addTableButton`}
                className={this.props.classes.buttonIcons}
                onClick={this.onAddTable}
              >
                <TableImageIcon />
              </Button>

              <Button
                data-testid={`${this.props.panelProps.testId}-openPanel`}
                id={`${this.props.panelProps.testId}-openPanel`}
                aria-owns={anchorEl ? 'simple-menu' : undefined}
                variant="contained"
                aria-haspopup="true"
                color="primary"
                onClick={(event: MouseEvent<HTMLElement>) =>
                  this.setState({ anchorEl: event.currentTarget })
                }
              >
                Edit
              </Button>

              <Menu
                id={`${this.props.panelProps.testId}-panelMenu`}
                anchorEl={anchorEl}
                open={Boolean(anchorEl)}
                onClose={this.handlePanelMenuClose('none')}
              >
                <MenuItem
                  id={`${this.props.panelProps.testId}-panelEditButton`}
                  data-testid={`${this.props.panelProps.testId}-panelEditButton`}
                  onClick={this.handlePanelMenuClose('edit')}
                >
                  Edit
                </MenuItem>
                <MenuItem
                  id={`${this.props.panelProps.testId}-panelDeleteButton`}
                  data-testid={`${this.props.panelProps.testId}-panelDeleteButton`}
                  onClick={this.handlePanelMenuClose('delete')}
                >
                  Delete
                </MenuItem>
                <MenuItem
                  data-testid={`${this.props.panelProps.testId}-panelAddBeforeButton`}
                  id={`${this.props.panelProps.testId}-panelAddBeforeButton`}
                  onClick={this.handlePanelMenuClose('add-before')}
                >
                  Add Panel Before
                </MenuItem>
                <MenuItem
                  id={`${this.props.panelProps.testId}-panelAddAfterButton`}
                  data-testid={`${this.props.panelProps.testId}-panelAddAfterButton`}
                  onClick={this.handlePanelMenuClose('add-after')}
                >
                  Add Panel After
                </MenuItem>

                <MenuItem
                  id={`${this.props.panelProps.testId}-panelAddAfterButton`}
                  data-testid={`${this.props.panelProps.testId}-movePanelForward`}
                  onClick={this.handlePanelMenuClose('move-forward')}
                >
                  Move Panel Forward
                </MenuItem>

                <MenuItem
                  id={`${this.props.panelProps.testId}-panelAddAfterButton`}
                  data-testid={`${this.props.panelProps.testId}-movePanelBackwards`}
                  onClick={this.handlePanelMenuClose('move-backward')}
                >
                  Move Panel Backward
                </MenuItem>
              </Menu>
            </Paper>
          </>
        )}
        <Editor
          data-testid={`${this.props.panelProps.testId}-editor`}
          readOnly={readOnly}
          autoCorrect={false}
          spellCheck={true}
          ref={this.ref}
          value={this.props.value}
          onChange={this.onChange}
          renderNode={this.renderNode}
          renderMark={this.renderMark}
        />
      </>
    );
  }
}

// @ts-ignore
export default withStyles(styles)(B6AEditor);
