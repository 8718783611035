import React, { Component } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DialogProps } from '../..';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { COLORS, ICONS } from '../../../../consts';
import Icon from '@material-ui/core/Icon';
import ImageUploader from '../../../ImageUploader';

interface EditPaperDialogProps extends DialogProps {
  icon?: string;
  color?: string;
  panelType: string;
  size: number;
  imageUrl?: string;
  title?: string;
  stat?: string;
  value?: string;
}

interface EditPaperDialogValues {
  icon?: string;
  color?: string;
  panelType: string;
  size: number;
  imageUrl?: string;
  title?: string;
  stat?: string;
  value?: string;
}

const styles = (theme: Theme) => ({
  form: {
    width: 100,
    display: 'block',
  },
  formField: {
    width: '100%',
  },
});

class EditPaperDialog extends Component<EditPaperDialogProps & WithStyles<typeof styles>> {
  onImageUpload(setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void) {
    return (url: string) => {
      setFieldValue('imageUrl', url);
    };
  }

  render() {
    const {
      icon,
      color,
      onClose,
      classes,
      panelType,
      imageUrl,
      title,
      value,
      size,
      stat,
    } = this.props;

    return (
      <Formik<EditPaperDialogValues>
        initialValues={{ icon, color, panelType, imageUrl, title, value, size, stat }}
        onSubmit={values => {
          onClose('edit-paper', values);
        }}
      >
        {({ values, setFieldValue }) => (
          <Form>
            <DialogTitle>Edit Box</DialogTitle>
            <DialogContent>
              <DialogContentText>Change the properties of the box</DialogContentText>

              <FormControl margin="normal" className={classes.form}>
                <InputLabel htmlFor="type">Type</InputLabel>
                <Field
                  name="panelType"
                  component={Select}
                  fullWidth
                  inputProps={{
                    name: 'panelType',
                    id: 'panelType',
                  }}
                >
                  <MenuItem value="text">Text</MenuItem>
                  <MenuItem value="stat">Stat</MenuItem>
                  <MenuItem value="photo">Photo</MenuItem>
                </Field>
              </FormControl>

              <FormControl margin="normal" className={classes.form}>
                <InputLabel htmlFor="size">Size</InputLabel>
                <Field
                  name="size"
                  component={Select}
                  fullWidth
                  inputProps={{
                    name: 'size',
                    id: 'size',
                  }}
                >
                  <MenuItem value={3}>1/4</MenuItem>
                  <MenuItem value={4}>1/3</MenuItem>
                  <MenuItem value={6}>1/2</MenuItem>
                  <MenuItem value={8}>2/3</MenuItem>
                  <MenuItem value={12}>Full</MenuItem>
                </Field>
              </FormControl>

              {values.panelType !== 'photo' && (
                <>
                  <FormControl margin="normal" className={classes.form}>
                    <InputLabel htmlFor="icon">Icon</InputLabel>
                    <Field
                      name="icon"
                      component={Select}
                      fullWidth
                      inputProps={{
                        name: 'icon',
                        id: 'icon',
                      }}
                    >
                      {ICONS.map(v => (
                        <MenuItem value={v} key={`icons-${v}`}>
                          <Icon>{v}</Icon>
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>

                  <FormControl margin="normal" className={classes.form}>
                    <InputLabel htmlFor="color">Color</InputLabel>
                    <Field
                      name="color"
                      component={Select}
                      fullWidth
                      inputProps={{
                        name: 'color',
                        id: 'color',
                      }}
                    >
                      {COLORS.map(v => (
                        <MenuItem value={v} key={`colors-${v}`}>
                          {v}
                        </MenuItem>
                      ))}
                    </Field>
                  </FormControl>
                </>
              )}

              {/*Text Specific Fields */}
              {values.panelType === 'text' && (
                <Field type="text" label="Title" name="title" component={TextField} fullWidth />
              )}

              {/*Stat Specific Fields */}

              {values.panelType === 'stat' && (
                <>
                  <Field
                    id="stat-input"
                    type="text"
                    label="Stat"
                    name="stat"
                    component={TextField}
                    fullWidth
                  />
                  <Field
                    id="value-input"
                    type="text"
                    label="Value"
                    name="value"
                    component={TextField}
                    fullWidth
                  />
                </>
              )}

              {/*Photo Specific Fields */}
              {values.panelType === 'photo' && (
                <ImageUploader imageUrl={imageUrl} onSuccess={this.onImageUpload(setFieldValue)} />
              )}
            </DialogContent>

            <DialogActions>
              <Button
                data-testid="dialogCancel"
                onClick={() => this.props.onClose('cancel')}
                color="primary"
              >
                Cancel
              </Button>
              <Button variant="contained" data-testid="dialogAccept" type="submit" color="primary">
                Submit
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    );
  }
}

// @ts-ignore
export default withStyles(styles)(EditPaperDialog);
