import React, { Component } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DialogProps } from '../..';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import 'url-search-params-polyfill';

class AddVideoDialog extends Component<DialogProps> {
  render() {
    const { editor, onClose } = this.props;

    return (
      <Formik
        initialValues={{ url: '' }}
        onSubmit={values => {
          if (editor) {
            const params = new URLSearchParams(values.url.split('?')[1]);
            const src = params.get('v');

            editor.insertBlock({
              type: 'video',
              data: { src },
            });
          }

          onClose('add-image');
        }}
      >
        {({ isSubmitting }) => (
          <Form>
            <DialogTitle>Insert an Video</DialogTitle>
            <DialogContent>
              <DialogContentText>Paste the URL of the YouTube Video.</DialogContentText>
            </DialogContent>

            <Field type="text" name="url" fullWidth component={TextField} />
            <DialogActions>
              <Button
                data-testid="dialogCancel"
                onClick={() => this.props.onClose('cancel')}
                color="primary"
              >
                Cancel
              </Button>
              <Button data-testid="dialogAccept" type="submit" variant="contained" color="primary">
                Insert
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    );
  }
}

export default AddVideoDialog;
