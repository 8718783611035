import Layout from '../../components/Layout';
import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import NoMatch from '../NoMatch';
import { RouteComponentProps } from 'react-router';
import { Theme, withStyles, WithStyles } from '@material-ui/core';
import PageEditor from '../../components/PageEditor';
import Typography from '@material-ui/core/Typography';
import { queryHandle, withRoot } from 'b6a-components/dist';

const GET_PAGE = gql`
  query getPage($slug: String!, $route: String!, $version: ID!) {
    page(clientSlug: $slug, route: $route) {
      title
      route
      inMenuBar
      menuBarIcon
      menuBarTitle
      id
      panels(version: $version) {
        id
        order
        size
        panelType
        document
        ... on TextPanel {
          color
          icon
          title
        }
        ... on StatsPanel {
          color
          icon
          stat
          value
        }
        ... on PhotoPanel {
          imageUrl
        }
      }
      pageVersions {
        id
        created
      }
    }
  }
`;

interface PanelInterface {
  id: string;
  order: number;
  size: number;
  panelType: string;
  document: string;
}

interface TextPanel extends PanelInterface {
  color: string;
  icon: string;
  title: string;
}

interface StatsPanel extends PanelInterface {
  color: string;
  icon: string;
  stat: string;
  value: string;
}

interface PhotoPanel extends PanelInterface {
  imageUrl: string;
}

type Panels = TextPanel | StatsPanel | PhotoPanel;

interface GetPageResult {
  page?: {
    title: string;
    menuBarTitle: string;
    menuBarIcon: string;
    inMenuBar: boolean;
    route: string;
    id: string;
    panels: Panels[];
    pageVersions: {
      id: string;
      created: string;
    }[];
  };
}

interface GetPageVariables {
  slug: string;
  route: string;
  version: string;
}

interface DashboardRouteProps {
  client: string;
  route: string;
}

interface EditPageState {
  version: string;
}

const styles = (theme: Theme) => ({});

class EditPage extends React.Component<
  RouteComponentProps<DashboardRouteProps> & WithStyles<typeof styles>,
  EditPageState
> {
  version: string = 'latest';

  // Render the editor.
  render() {
    const { match } = this.props;

    return (
      <Query<GetPageResult, GetPageVariables>
        query={GET_PAGE}
        fetchPolicy="network-only"
        notifyOnNetworkStatusChange={true}
        variables={{
          version: 'latest',
          slug: match.params.client,
          route: match.params.route,
        }}
      >
        {queryHandle<GetPageResult, GetPageVariables>((data, refetch) => {
          const page = data ? data.page : undefined;

          if (page) {
            return (
              <Layout isAdmin={true} showClientRoutes={true} currentClient={match.params.client}>
                <Typography variant="h3" gutterBottom={true} id="pagetitle">
                  {page.title}
                </Typography>
                <PageEditor
                  client={match.params.client}
                  route={match.params.route}
                  panels={page.panels}
                  title={page.title}
                  menuBarTitle={page.menuBarTitle}
                  menuBarIcon={page.menuBarIcon}
                  inMenuBar={page.inMenuBar}
                  pageId={page.id}
                  version={this.version}
                  onVersionChange={(newVersion: string) => {
                    refetch!({
                      version: newVersion,
                      slug: match.params.client,
                      route: match.params.route,
                    });
                    this.version = newVersion;
                  }}
                  pageVersions={page.pageVersions}
                />
              </Layout>
            );
          }
          return <NoMatch />;
        })}
      </Query>
    );
  }
}

export default withRoot(withStyles(styles)(EditPage));
