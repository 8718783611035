import Layout from '../../components/Layout';
import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import NoMatch from '../NoMatch';
import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { List, Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import ClientIcon from '@material-ui/icons/DomainRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import UsersIcon from '@material-ui/icons/GroupRounded';
import AddUserIcon from '@material-ui/icons/PersonAddRounded';
import ListItemLink from '../../components/ListItemLink';
import { IconButton, Panel, queryHandle, withRoot } from 'b6a-components/dist';

const GET_DASHBOARD = gql`
  query {
    clients {
      name
      slug
      id
    }
    users {
      email
      firstName
      lastName
      id
    }
  }
`;

const styles = (theme: Theme) => ({
  nav: {
    width: '100%',
    backgroundColor: theme.palette.background.paper,
  },
  button: {
    margin: theme.spacing.unit,
  },
});

interface GetDashboard {
  clients?: {
    name: string;
    slug: string;
    id: string;
  }[];
  users?: {
    email: string;
    firstName: string;
    lastName: string;
    id: string;
  }[];
}

interface GetDashboardVariables {}

interface DashboardRouteProps {}
interface DashboardProps extends WithStyles<typeof styles> {}

class Dashboard extends React.Component<RouteComponentProps<DashboardRouteProps> & DashboardProps> {
  // Render the editor.
  render() {
    const { classes } = this.props;

    return (
      <Query<GetDashboard, GetDashboardVariables> query={GET_DASHBOARD}>
        {queryHandle(data => {
          if (data) {
            const { clients, users } = data;
            const ClientsLink = (props: any) => <Link to="/admin/clients" {...props} />;
            const AddClientLink = (props: any) => <Link to="/admin/add-client" {...props} />;
            const UsersLink = (props: any) => <Link to="/admin/users" {...props} />;
            const AddUserLink = (props: any) => <Link to="/admin/add-user" {...props} />;

            return (
              <Layout isAdmin={true}>
                <Typography variant="h2">Admin Dashboard</Typography>
                <Grid container spacing={16}>
                  <Panel size={4} color="RED" icon="domain_rounded" title="Clients">
                    <Typography>Number of Clients: {clients ? clients.length : 0}</Typography>
                    <Typography variant="h6">Last 5 Clients</Typography>
                    <List component="nav" className={classes.nav}>
                      {clients &&
                        clients
                          .slice()
                          .reverse()
                          .slice(0, 5)
                          .map(client => (
                            <ListItemLink
                              key={client.slug}
                              anchor={`/admin/client/${client.slug}`}
                              text={`${client.name} (${client.id})`}
                            />
                          ))}
                    </List>
                    <IconButton
                      className={classes.button}
                      variant="text"
                      component={ClientsLink}
                      color="primary"
                      text="All Clients"
                      icon={<ClientIcon />}
                    />

                    <IconButton
                      className={classes.button}
                      variant="contained"
                      component={AddClientLink}
                      color="secondary"
                      text="New Client"
                      icon={<AddIcon />}
                    />
                  </Panel>

                  <Panel size={4} color="GREEN" icon="group_rounded" title="Users">
                    <Typography>Number of Users: {users ? users.length : 0}</Typography>
                    <Typography variant="h6">Last 5 Users</Typography>
                    <List component="nav" className={classes.nav}>
                      {users &&
                        users
                          .slice()
                          .reverse()
                          .slice(0, 5)
                          .map(user => (
                            <ListItemLink
                              key={user.id}
                              anchor={`/admin/user/${user.id}`}
                              text={`${user.lastName}, ${user.firstName} (${user.id})`}
                            />
                          ))}
                    </List>
                    <IconButton
                      className={classes.button}
                      variant="text"
                      component={UsersLink}
                      color="primary"
                      text="All Users"
                      icon={<UsersIcon />}
                    />

                    <IconButton
                      className={classes.button}
                      variant="contained"
                      component={AddUserLink}
                      color="secondary"
                      text="New User"
                      icon={<AddUserIcon />}
                    />
                  </Panel>
                </Grid>
              </Layout>
            );
          }
          return <NoMatch />;
        })}
      </Query>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(Dashboard));
