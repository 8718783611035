import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import React from 'react';
import { Theme, withStyles, WithStyles } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { SuccessMessage, SaveButton, withRoot } from 'b6a-components/dist';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';

const DELETE_PAGE = gql`
  mutation deletePageFromClient($slug: String!, $route: String!) {
    removePageFromClient(slug: $slug, route: $route) {
      id
      name
      slug
      pages {
        route
        id
      }
      users {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

const styles = (theme: Theme) => ({});

interface DeletePageDialogProps extends WithStyles<typeof styles> {
  clientSlug: string;
  clientName: string;
  route: string;
  handleClose: () => void;
}

class DeletePageDialog extends React.Component<DeletePageDialogProps> {
  render() {
    const { clientSlug, route, clientName } = this.props;

    return (
      <Mutation mutation={DELETE_PAGE}>
        {(submit, { data, loading, error }) => {
          if (!loading && !error && data && data.removePageFromClient) {
            return (
              <>
                <DialogContent>
                  <SuccessMessage />
                </DialogContent>

                <DialogActions>
                  <Button onClick={this.props.handleClose} color="primary">
                    Close
                  </Button>
                  }
                </DialogActions>
              </>
            );
          }

          return (
            <>
              <DialogTitle id="form-dialog-title">Confirm?</DialogTitle>

              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete "/{route}" from {clientName}?
                </DialogContentText>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.props.handleClose} color="primary">
                  Cancel
                </Button>
                <SaveButton
                  loading={loading}
                  onClick={() => {
                    submit({ variables: { route, slug: clientSlug } });
                  }}
                >
                  Delete
                </SaveButton>
                }
              </DialogActions>
            </>
          );
        }}
      </Mutation>
    );
  }
}

export default withRoot(withStyles(styles)(DeletePageDialog));
