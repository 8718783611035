import Layout from '../../../components/Layout';
import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import NoMatch from '../../NoMatch';
import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import EditIcon from '@material-ui/icons/EditRounded';
import AddIcon from '@material-ui/icons/AddRounded';
import ReorderIcon from '@material-ui/icons/ReorderRounded';
import AddUserIcon from '@material-ui/icons/PersonAddRounded';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemLink from '../../../components/ListItemLink';
import TableBody from '@material-ui/core/TableBody';
import Dialog from '@material-ui/core/Dialog';
import AddPageDialog from './AddPageDialog';
import AssignUserDialog from './AssignUserDialog';
import DeletePageDialog from './DeletePageDialog';
import Button from '@material-ui/core/Button';
import { Panel, IconButton, SortableList, queryHandle, withRoot } from 'b6a-components/dist';
import { Item } from 'b6a-components/dist/components/SortableList';
import EditClientDetailPanel from './EditClientDetailPanel';

const GET_CLIENT = gql`
  query getClient($slug: String!) {
    client(slug: $slug) {
      id
      name
      slug
      pages {
        route
        menuBarTitle
        id
      }
      users {
        id
        email
        firstName
        lastName
      }
    }
    users {
      id
      email
      firstName
      lastName
    }
  }
`;

const REORDER_CLIENT_PAGES = gql`
  mutation reorderClientPages($slug: String!, $order: [ID!]!) {
    reorderClientPages(slug: $slug, order: $order) {
      id
      name
      slug
      pages {
        route
        id
      }
      users {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    table: {
      marginBottom: theme.spacing.unit * 4,
      width: '70%',
    },
    buttonRow: {
      marginBottom: theme.spacing.unit,
    },
  });

export interface Client {
  name: string;
  slug: string;
  id: string;
  pages: Page[];
  users: {
    id: string;
    email: string;
    lastName: string;
    firstName: string;
  }[];
}

export interface GetClient {
  client?: Client;
  users: {
    id: string;
    email: string;
    lastName: string;
    firstName: string;
  }[];
}

interface Page {
  route: string;
  id: string;
  // The title that will be showing the Menu Bar
  menuBarTitle: string;
}

interface GetClientVariables {
  slug: string;
}

interface ClientRouteProps {
  slug: string;
}

interface ClientProps extends WithStyles<typeof styles> {}

interface ClientState {
  dialogOpen: boolean;
  dialog: React.ReactElement<any> | null;
  selectedUser: string;
  reordering: boolean;
}

enum DialogOptions {
  ADD_PAGE,
  SET_USER,
  DELETE_PAGE,
  EDIT_CLIENT,
}

interface ReorderClientResult {
  name: string;
  slug: string;
  id: string;
  pages: Page[];
  users: {
    id: string;
    email: string;
    lastName: string;
    firstName: string;
  }[];
}

interface ReorderClientVariables {
  slug: string;
  order: string[];
}

class ClientPage extends React.Component<
  RouteComponentProps<ClientRouteProps> & ClientProps,
  ClientState
> {
  state = {
    dialogOpen: false,
    dialog: null,
    selectedUser: '',
    reordering: false,
  };

  orderedPages: Page[] = [];

  handleClickOpen = (data: GetClient, key: DialogOptions, props?: any) => () => {
    if (data.client === undefined) return null;
    const dialog: null | React.ReactElement<any> = (() => {
      switch (key) {
        case DialogOptions.ADD_PAGE:
          return (
            <AddPageDialog
              handleClose={this.handleClose}
              clientName={data.client.name}
              clientSlug={data.client.slug}
            />
          );
        case DialogOptions.SET_USER:
          return (
            <AssignUserDialog
              handleClose={this.handleClose}
              users={data.users}
              clientName={data.client.name}
              clientSlug={data.client.slug}
            />
          );
        case DialogOptions.DELETE_PAGE:
          if (props === undefined) return null;
          return (
            <DeletePageDialog
              handleClose={this.handleClose}
              clientName={data.client.name}
              clientSlug={data.client.slug}
              route={props.route}
            />
          );
        case DialogOptions.EDIT_CLIENT:
          if (props === undefined) return null;
          return <EditClientDetailPanel handleClose={this.handleClose} client={data.client} />;
      }
    })();

    this.setState({ dialog, dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialog: null, dialogOpen: false });
  };

  renderReorder(slug: string, pages: Page[]) {
    return (
      <Mutation<ReorderClientResult, ReorderClientVariables> mutation={REORDER_CLIENT_PAGES}>
        {reorder => {
          return (
            <>
              <Button
                variant="contained"
                color="primary"
                onClick={async () => {
                  await reorder({
                    variables: { slug, order: this.orderedPages.map(page => page.id) },
                  });
                  this.setState({ reordering: false });
                }}
                disabled={this.orderedPages === []}
              >
                Save
              </Button>

              <Button variant="text" onClick={() => this.setState({ reordering: false })}>
                Cancel
              </Button>
              <SortableList
                items={pages.map((page: Page) => ({
                  text: `${page.menuBarTitle}`,
                  ...page,
                }))}
                onReorder={(newList: Item[]) =>
                  (this.orderedPages = (newList as unknown) as Page[])
                }
              />
            </>
          );
        }}
      </Mutation>
    );
  }

  render() {
    const { classes, match } = this.props;

    return (
      <Query<GetClient, GetClientVariables>
        query={GET_CLIENT}
        variables={{
          slug: match.params.slug,
        }}
      >
        {queryHandle(data => {
          if (data && data.client) {
            const { client } = data;

            return (
              <Layout isAdmin={true}>
                <Grid container spacing={16}>
                  <Panel
                    size={6}
                    color="RED"
                    icon="domain_rounded"
                    title={`Client: ${client.name}`}
                  >
                    <IconButton
                      variant="contained"
                      color="primary"
                      text="Edit Client"
                      icon={<EditIcon />}
                      onClick={this.handleClickOpen(data, DialogOptions.EDIT_CLIENT, null)}
                      className={classes.buttonRow}
                    />
                    <Grid container spacing={16}>
                      <Grid item lg={12} xs={12}>
                        <Typography variant="h5">Details</Typography>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="right">Attribute</TableCell>
                              <TableCell align="left">Value</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow>
                              <TableCell align="right">ID</TableCell>
                              <TableCell align="left">{client.id}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="right">Name</TableCell>
                              <TableCell align="left">{client.name}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="right">Slug</TableCell>
                              <TableCell align="left">{client.slug}</TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>
                      </Grid>

                      <Grid item lg={6} xs={12}>
                        <Typography variant="h5">Pages</Typography>
                        {!this.state.reordering ? (
                          <>
                            <IconButton
                              variant="contained"
                              color="secondary"
                              text="Add Page"
                              icon={<AddIcon />}
                              onClick={this.handleClickOpen(data, DialogOptions.ADD_PAGE)}
                            />
                            {client.pages.length > 1 && (
                              <IconButton
                                variant="text"
                                text="Reorder"
                                color="primary"
                                icon={<ReorderIcon />}
                                onClick={() => {
                                  this.setState({ reordering: true });
                                  this.orderedPages = [];
                                }}
                              />
                            )}
                            <List component="nav">
                              {client.pages.map(page => (
                                <ListItemLink
                                  key={page.route}
                                  anchor={`/admin/edit-page/${client.slug}/${page.route}`}
                                  text={page.menuBarTitle}
                                  icon={<EditIcon />}
                                  deleteCb={this.handleClickOpen(data, DialogOptions.DELETE_PAGE, {
                                    route: page.route,
                                  })}
                                />
                              ))}
                            </List>
                          </>
                        ) : (
                          this.renderReorder(match.params.slug, client.pages)
                        )}
                      </Grid>
                    </Grid>
                  </Panel>

                  <Panel size={6} color="GREEN" icon="person_rounded" title="Client Assigned Users">
                    <Typography variant="h5">Users</Typography>
                    <IconButton
                      variant="contained"
                      color="secondary"
                      text="Assign User"
                      icon={<AddUserIcon />}
                      onClick={this.handleClickOpen(data, DialogOptions.SET_USER)}
                    />
                    <List component="nav">
                      {client.users.map(user => (
                        <ListItemLink
                          key={user.id}
                          anchor={`/admin/user/${user.id}`}
                          text={`${user.lastName}, ${user.firstName} <${user.email}>`}
                        />
                      ))}
                    </List>
                  </Panel>
                </Grid>

                {this.state.dialog && (
                  <Dialog
                    open={this.state.dialogOpen}
                    onClose={this.handleClose}
                    aria-labelledby="form-dialog-title"
                    maxWidth="md"
                  >
                    {this.state.dialog}
                  </Dialog>
                )}
              </Layout>
            );
          }
          return <NoMatch />;
        })}
      </Query>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(ClientPage));
