import Layout from '../../../components/Layout';
import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import NoMatch from '../../NoMatch';
import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import { createStyles, Theme, WithStyles, withStyles } from '@material-ui/core';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableCell from '@material-ui/core/TableCell';
import Typography from '@material-ui/core/Typography';
import List from '@material-ui/core/List';
import ListItemLink from '../../../components/ListItemLink';
import TableBody from '@material-ui/core/TableBody';
import moment from 'moment';
import EditIcon from '@material-ui/icons/EditRounded';
import {
  Panel,
  SuccessMessage,
  SaveButton,
  queryHandle,
  withRoot,
  IconButton,
} from 'b6a-components';
import Dialog from '@material-ui/core/Dialog';
import EditUserDetailPanel from './EditUserDetailPanel';

const GET_USER = gql`
  query getUser($id: ID!) {
    user(id: $id) {
      id
      email
      firstName
      lastName
      isAdmin
      created
      passwordChanged
      lastLogin
      verified
      clients {
        name
        id
        slug
      }
    }
  }
`;

const RESEND_INVITATION_EMAIL = gql`
  mutation resendInvitationEmail($email: String!) {
    resendInvitationEmail(email: $email)
  }
`;

const styles = (theme: Theme) =>
  createStyles({
    table: {
      marginBottom: theme.spacing.unit * 4,
      width: '70%',
    },
    buttonRow: {
      marginBottom: theme.spacing.unit,
    },
  });

export interface GetUser {
  user?: User;
}

export interface User {
  email: string;
  firstName: string;
  lastName: string;
  isAdmin: boolean;
  verified: boolean;
  id: string;
  created: Date;
  passwordChanged: Date;
  lastLogin: Date;
  clients: {
    id: string;
    name: string;
    slug: string;
  }[];
}

enum DialogOptions {
  EDIT_USER,
}

interface GetUserVariables {
  id: string;
}

interface UserRouteProps {
  id: string;
}

interface UserProps extends WithStyles<typeof styles> {}

interface UserState {
  hideButton: boolean;
  dialogOpen: boolean;
  dialog: React.ReactElement<any> | null;
}

class UserPage extends React.Component<RouteComponentProps<UserRouteProps> & UserProps, UserState> {
  state = {
    hideButton: false,
    dialogOpen: false,
    dialog: null,
  };

  handleClickOpen = (data: GetUser, key: DialogOptions, props?: any) => () => {
    if (data.user === undefined) return null;
    const dialog: null | React.ReactElement<any> = (() => {
      switch (key) {
        case DialogOptions.EDIT_USER:
          return <EditUserDetailPanel handleClose={this.handleClose} user={data.user} />;
      }
    })();

    this.setState({ dialog, dialogOpen: true });
  };

  handleClose = () => {
    this.setState({ dialog: null, dialogOpen: false });
  };

  render() {
    const { classes, match } = this.props;
    const dateFormat = 'ddd, MMM Do YYYY, h:mm:ss a';

    return (
      <Query<GetUser, GetUserVariables>
        query={GET_USER}
        variables={{
          id: match.params.id,
        }}
      >
        {queryHandle(data => {
          if (data && data.user) {
            const { user } = data;

            return (
              <Layout isAdmin={true}>
                <Grid container spacing={16}>
                  <Panel
                    size={6}
                    color="GREEN"
                    icon="person_rounded"
                    title={`User: ${user.lastName}, ${user.firstName}`}
                  >
                    <IconButton
                      className={classes.buttonRow}
                      variant="contained"
                      color="secondary"
                      text="Edit User"
                      icon={<EditIcon />}
                      onClick={this.handleClickOpen(data, DialogOptions.EDIT_USER)}
                    />
                    <Grid container spacing={16}>
                      <Grid item lg={12} xs={12}>
                        <Typography variant="h5">Details</Typography>
                        <Table className={classes.table}>
                          <TableHead>
                            <TableRow>
                              <TableCell align="right">Attribute</TableCell>
                              <TableCell align="left">Value</TableCell>
                            </TableRow>
                          </TableHead>

                          <TableBody>
                            <TableRow>
                              <TableCell align="right">First Name</TableCell>
                              <TableCell align="left">{user.firstName}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="right">Last Name</TableCell>
                              <TableCell align="left">{user.lastName}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="right">Email</TableCell>
                              <TableCell align="left">{user.email}</TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="right">Last Login</TableCell>
                              <TableCell align="left">
                                {user.verified
                                  ? moment(user.lastLogin).format(dateFormat)
                                  : 'Never Logged In'}
                              </TableCell>
                            </TableRow>

                            <TableRow>
                              <TableCell align="right">Admin?</TableCell>
                              <TableCell align="left">{user.isAdmin ? 'True' : 'False'}</TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="right">Created</TableCell>
                              <TableCell align="left">
                                {moment(user.created).format(dateFormat)}
                              </TableCell>
                            </TableRow>
                            <TableRow>
                              <TableCell align="right">Password Last Changed</TableCell>
                              <TableCell align="left">
                                {moment(user.passwordChanged).format(dateFormat)}
                              </TableCell>
                            </TableRow>
                          </TableBody>
                        </Table>

                        {!user.verified && (
                          <Mutation<{ email: string }> mutation={RESEND_INVITATION_EMAIL}>
                            {(submit, { loading, data, error }) => (
                              <>
                                {!loading && !error && data && (
                                  <SuccessMessage message="Email Resent Successfully" />
                                )}
                                <SaveButton
                                  loading={loading}
                                  color="primary"
                                  variant="contained"
                                  onClick={() => {
                                    submit({ variables: { email: user.email } });
                                    this.setState({ hideButton: true });
                                  }}
                                >
                                  Resend Invitation Email
                                </SaveButton>
                              </>
                            )}
                          </Mutation>
                        )}
                      </Grid>

                      <Grid item lg={7} xs={12}>
                        <Typography variant="h5">Clients</Typography>
                        <List component="nav">
                          {user.clients.map(client => (
                            <ListItemLink
                              key={client.id}
                              anchor={`/admin/client/${client.slug}`}
                              text={`${client.name}`}
                            />
                          ))}
                        </List>
                      </Grid>
                    </Grid>
                    {this.state.dialog && (
                      <Dialog
                        open={this.state.dialogOpen}
                        onClose={this.handleClose}
                        aria-labelledby="form-dialog-title"
                        maxWidth="md"
                      >
                        {this.state.dialog}
                      </Dialog>
                    )}
                  </Panel>
                </Grid>
              </Layout>
            );
          }
          return <NoMatch />;
        })}
      </Query>
    );
  }
}

export default withRoot(withStyles(styles)(UserPage));
