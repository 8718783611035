import React from 'react';
import * as Sentry from '@sentry/browser';
import { ErrorMessage } from 'b6a-components/dist';

class ErrorBoundary extends React.Component<{}, { errorId?: string }> {
  state = {
    errorId: undefined,
  };

  componentDidCatch(error: Error, errorInfo: any) {
    Sentry.withScope(scope => {
      Object.keys(errorInfo).forEach(key => {
        scope.setExtra(key, errorInfo[key]);
      });
      this.setState({ errorId: Sentry.captureException(error) });
    });
  }

  render() {
    const { children } = this.props;

    if (this.state.errorId) {
      // You can render any custom fallback UI
      return (
        <ErrorMessage
          header="Error loading page"
          message="Sorry, unfortunately there was an error loading this page. This has been
          reported to the admins automatically."
          sentryErrorId={this.state.errorId}
        />
      );
    }

    return <>{children}</>;
  }
}

export default ErrorBoundary;
