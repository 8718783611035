import * as React from 'react';
import { Theme, Typography, WithStyles, withStyles } from '@material-ui/core';
import CssBaseline from '@material-ui/core/CssBaseline';
import { Panel, IconButton, withRoot } from 'b6a-components/dist';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import ReturnIcon from '@material-ui/icons/ArrowBackRounded';
import { Link } from 'react-router-dom';

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
    margin: theme.spacing.unit * 3,
  },
});

interface NoMatchProps extends WithStyles<typeof styles> {}

class NoMatch extends React.Component<NoMatchProps> {
  DashboardLink = (props: any) => <Link to="/dashboard" {...props} />;
  LoginLink = (props: any) => <Link to="/login" {...props} />;

  render() {
    const { classes } = this.props;

    return (
      <div className={classes.root} data-testid="404">
        <CssBaseline />
        <Panel size={12} icon="clear_rounded" color="RED">
          <Typography variant="h6">
            Unfortunately, either you do not have access to this page or it does not exist.
          </Typography>
          <Typography>
            Either press the back button on your browser or return to the main page.&nbsp;
            <IconButton
              component={this.DashboardLink}
              color="primary"
              variant="contained"
              icon={<ReturnIcon />}
              text="Return"
            />
          </Typography>
          <Typography>
            If you believe you should be able to access this page &nbsp;
            <IconButton
              component={this.LoginLink}
              color="primary"
              variant="contained"
              icon={<LockOutlinedIcon />}
              text="Login Here"
            />
          </Typography>
        </Panel>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(NoMatch));
