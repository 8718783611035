import { ListItem, ListItemText, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import React from 'react';
import MuiIconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/DeleteRounded';
import ListItemAvatar from '@material-ui/core/ListItemAvatar';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import { withRoot } from 'b6a-components/dist';

interface ListItemLinkProps {
  anchor: string;
  text: string;
  icon?: React.ReactElement<any>;
  deleteCb?: () => void;
}

const styles = (theme: Theme) => ({
  deleteButton: {
    color: theme.palette.error.main,
  },
});

class ListItemLink extends React.Component<ListItemLinkProps & WithStyles<typeof styles>> {
  render() {
    const { anchor, text, icon, deleteCb, classes } = this.props;

    return (
      // @ts-ignore
      <ListItem button component={({ ...props }) => <Link {...props} to={anchor} />}>
        {icon && <ListItemAvatar>{icon}</ListItemAvatar>}
        <ListItemText primary={text} />
        {deleteCb && (
          <ListItemSecondaryAction>
            <MuiIconButton className={classes.deleteButton} aria-label="Delete" onClick={deleteCb}>
              <DeleteIcon />
            </MuiIconButton>
          </ListItemSecondaryAction>
        )}
      </ListItem>
    );
  }
}

export default withRoot(withStyles(styles)(ListItemLink));
