import Layout from '../../components/Layout';
import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import NoMatch from '../NoMatch';
import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from 'material-table';
import { Theme, WithStyles, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/EditRounded';
import CheckmarkIcon from '@material-ui/icons/CheckRounded';
import XIcon from '@material-ui/icons/CloseRounded';
import { Panel, queryHandle, withRoot } from 'b6a-components/dist';
import moment from 'moment';

const GET_USERS = gql`
  query {
    users {
      email
      firstName
      lastName
      id
      isAdmin
      lastLogin
    }
  }
`;

const styles = (theme: Theme) => ({
  green: {
    color: theme.palette.secondary.main,
  },
  red: {
    color: theme.palette.error.main,
  },
});

interface User {
  name: string;
  slug: string;
  id: string;
  isAdmin: boolean;
  lastLogin: string;
}

interface GetUsers {
  users?: User[];
}

interface GetUsersVariables {}

interface UsersRouteProps {}
interface UsersProps extends WithStyles<typeof styles> {}

const UserPageLink = (slug: string) => (props: any) => (
  <Link to={`/admin/user/${slug}`} {...props} />
);

class UsersPage extends React.Component<RouteComponentProps<UsersRouteProps> & UsersProps> {
  columns = () => {
    const { classes } = this.props;
    const dateFormat = 'M/D/YY, h:mm:ss a';

    return [
      {
        title: '',
        field: 'id',
        filtering: false,
        cellStyle: { width: 20 },
        render: (rowData: User) => (
          <>
            <IconButton component={UserPageLink(rowData.id)}>
              <EditIcon />
            </IconButton>
          </>
        ),
      },
      { title: 'ID', field: 'id' },
      { title: 'Email', field: 'email' },
      { title: 'First Name', field: 'firstName' },
      { title: 'Last Name', field: 'lastName' },
      {
        title: 'Is Admin',
        field: 'isAdmin',
        type: 'boolean' as 'boolean',
        render: (rowData: User) =>
          rowData.isAdmin ? (
            <CheckmarkIcon className={classes.green} />
          ) : (
            <XIcon className={classes.red} />
          ),
      },
      {
        title: 'Last Login',
        field: 'lastLogin',
        type: 'date' as 'date',
        filtering: false,
        render: (rowData: User) =>
          rowData.lastLogin ? moment(rowData.lastLogin).format(dateFormat) : 'Never',
      },
    ];
  };

  // Render the editor.
  render() {
    return (
      <Query<GetUsers, GetUsersVariables> query={GET_USERS}>
        {queryHandle(data => {
          if (data) {
            const { users } = data;

            return (
              <Layout isAdmin={true}>
                <Grid container spacing={16}>
                  <Panel size={12} color="GREEN" icon="group_rounded" title="Users">
                    {users ? (
                      <MaterialTable
                        columns={this.columns()}
                        data={users}
                        title=""
                        options={{
                          columnsButton: true,
                          filtering: true,
                          pageSize: 10,
                          pageSizeOptions: [10, 15, 20],
                        }}
                      />
                    ) : (
                      'no users found'
                    )}
                  </Panel>
                </Grid>
              </Layout>
            );
          }
          return <NoMatch />;
        })}
      </Query>
    );
  }
}

export default withRoot(withStyles(styles)(UsersPage));
