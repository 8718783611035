import Layout from '../../components/Layout';
import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { Panel, ErrorMessage, SuccessMessage, SaveButton, withRoot } from 'b6a-components/dist';

const ADD_CLIENT = gql`
  mutation addUser($firstName: String!, $lastName: String!, $email: String!) {
    newUser(firstName: $firstName, lastName: $lastName, email: $email) {
      id
    }
  }
`;

const styles = (theme: Theme) => ({
  buttonRow: {
    marginTop: theme.spacing.unit * 2,
  },
});

interface AddClientMutation {
  newUser?: {
    id: string;
  };
}

interface AddClientsVariables {}

interface AddClientValues {
  firstName: string;
  lastName: string;
  email: string;
}

interface ClientsProps extends WithStyles<typeof styles> {}

class AddClientPage extends React.Component<ClientsProps> {
  onSubmit(addClient: (values: any) => void) {
    return (values: AddClientValues) => {
      addClient({
        variables: values,
      });
    };
  }

  renderForm(addClient: () => void, loading: boolean) {
    const { classes } = this.props;

    return (
      <Formik<AddClientValues>
        initialValues={{ firstName: '', lastName: '', email: '' }}
        validationSchema={this.validator}
        onSubmit={this.onSubmit(addClient)}
      >
        {({ resetForm, errors, touched }) => {
          const isError = Object.keys(errors).length > 0 || Object.keys(touched).length !== 3;

          return (
            <Form>
              <Field
                label="First Name"
                type="text"
                name="firstName"
                fullWidth
                required
                autoComplete="off"
                component={TextField}
              />
              <Field
                label="Last Name"
                type="text"
                name="lastName"
                fullWidth
                required
                autoComplete="off"
                component={TextField}
              />

              <Field
                label="Email"
                type="email"
                name="email"
                fullWidth
                required
                autoComplete="off"
                component={TextField}
              />

              <div className={classes.buttonRow}>
                <SaveButton loading={loading} disabled={isError}>
                  Create and Send Invitation
                </SaveButton>
                <Button onClick={() => resetForm()}>Reset</Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  validator = yup.object().shape({
    firstName: yup.string().required('First Name is Required'),
    lastName: yup.string().required('First Name is Required'),
    email: yup
      .string()
      .email('Email must be a proper email')
      .required('Email is Required'),
  });

  render() {
    return (
      <Layout isAdmin={true}>
        <Grid container={true} justify="center">
          <Panel color="GREEN" size={6} icon="person_add_rounded" title="Create New User">
            <Mutation<AddClientMutation, AddClientsVariables> mutation={ADD_CLIENT}>
              {(addClient, { loading, data, error }) => {
                return (
                  <>
                    {!loading && !error && data && data.newUser && <SuccessMessage />}
                    {error && <ErrorMessage header="Unable to Save" message={error.message} />}
                    {this.renderForm(addClient, loading)}
                  </>
                );
              }}
            </Mutation>
          </Panel>
        </Grid>
      </Layout>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(AddClientPage));
