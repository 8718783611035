import Layout from '../components/Layout';
import React from 'react';
import Page from '../components/Page';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import NoMatch from './NoMatch';
import { RouteComponentProps } from 'react-router';
import Typography from '@material-ui/core/Typography';
import { Value } from 'slate';
import { queryHandle } from 'b6a-components/dist';
import * as queryString from 'querystring';

const GET_PAGE = gql`
  query getPage($slug: String!, $route: String!, $version: ID!) {
    page(clientSlug: $slug, route: $route) {
      id
      title
      panels(version: $version) {
        id
        order
        size
        panelType
        document
        ... on TextPanel {
          color
          icon
          title
        }
        ... on StatsPanel {
          color
          icon
          stat
          value
        }
        ... on PhotoPanel {
          imageUrl
        }
      }
    }
  }
`;

interface PanelInterface {
  id: string;
  order: number;
  size: number;
  panelType: string;
  document: string;
}

interface TextPanel extends PanelInterface {
  color: string;
  icon: string;
  title: string;
}

interface StatsPanel extends PanelInterface {
  color: string;
  icon: string;
  stat: string;
  value: string;
}

interface PhotoPanel extends PanelInterface {
  imageUrl: string;
}

type Panels = TextPanel | StatsPanel | PhotoPanel;

interface GetPageResult {
  page?: {
    title: string;
    panels: Panels[];
  };
}

interface GetPageVariables {
  slug: string;
  route: string;
}

interface DashboardRouteProps {
  client: string;
  route: string;
  version: string;
}

class PageComponent extends React.Component<RouteComponentProps<DashboardRouteProps>> {
  // Render the editor.
  render() {
    const { match } = this.props;

    const q = queryString.parse(location.search);
    const version = q.v || q['?v'] || 'published';

    return (
      <Query<GetPageResult, GetPageVariables>
        query={GET_PAGE}
        notifyOnNetworkStatusChange={true}
        fetchPolicy="network-only"
        variables={{
          version,
          slug: match.params.client,
          route: match.params.route,
        }}
      >
        {queryHandle((data: GetPageResult | undefined) => {
          const page = data ? data.page : undefined;

          if (page) {
            document.title = `${page.title}`;

            return (
              <Layout isAdmin={false} currentClient={match.params.client}>
                <Typography variant="h3" gutterBottom={true} data-testid="pageTitle">
                  {page.title}
                </Typography>
                <Page
                  panels={page.panels.map(panel => {
                    return {
                      ...panel,
                      document: Value.fromJSON(JSON.parse(panel.document)),
                      readOnly: false,
                      testId: `panel-${panel.order}`,
                    };
                  })}
                  client={match.params.client}
                />
              </Layout>
            );
          }
          return <NoMatch />;
        })}
      </Query>
    );
  }
}

export default PageComponent;
