import * as React from 'react';

import { RouteComponentProps } from 'react-router';
import ClientIcon from '@material-ui/icons/DomainRounded';
import UsersIcon from '@material-ui/icons/GroupRounded';
import DashboardIcon from '@material-ui/icons/DashboardRounded';
import LogoutIcon from '@material-ui/icons/ExitToAppRounded';
import HomeIcon from '@material-ui/icons/Home';

import Page from './pages/Page';
import Login from './pages/Login';
import Logout from './pages/Logout';
import EditPage from './pages/Admin/EditPage';
import AdminDashboard from './pages/Admin/Dashboard';
import ClientsPage from './pages/Admin/ClientsPage';
import UsersPage from './pages/Admin/UsersPage';
import UserPage from './pages/Admin/User';
import AddClient from './pages/Admin/AddClient';
import ClientPage from './pages/Admin/Client';
import AddUser from './pages/Admin/AddUser';
import VerifyUser from './pages/VerifyUser';
import ResetPassword from './pages/ResetPassword';
import Dashboard from './pages/Dashboard';

export enum RouteType {
  CLIENT,
  ADMIN,
  ALWAYS_SHOW,
}

export interface Route {
  isPublic?: boolean;
  menuBarTitle?: string;
  path: string;
  routeType: RouteType;
  inMenuBar: boolean;
  icon?: React.ReactElement<any>;
  component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
  testid?: string;
}

// Dev notes: - Admin routes need to go first so that they are matched before a client named "admin"

export const ROUTES: Route[] = [
  // Admin Routes
  {
    menuBarTitle: 'Dashboard',
    path: '/admin',
    icon: <DashboardIcon />,
    component: AdminDashboard,
    inMenuBar: true,
    routeType: RouteType.ADMIN,
  },
  {
    menuBarTitle: 'Clients',
    path: '/admin/clients',
    icon: <ClientIcon />,
    component: ClientsPage,
    inMenuBar: true,
    routeType: RouteType.ADMIN,
  },
  {
    path: '/admin/client/:slug',
    component: ClientPage,
    inMenuBar: false,
    routeType: RouteType.ADMIN,
  },
  {
    menuBarTitle: 'Clients',
    path: '/admin/add-client',
    component: AddClient,
    inMenuBar: false,
    routeType: RouteType.ADMIN,
  },
  {
    menuBarTitle: 'Users',
    path: '/admin/users',
    icon: <UsersIcon />,
    component: UsersPage,
    inMenuBar: true,
    routeType: RouteType.ADMIN,
  },
  {
    menuBarTitle: 'Users',
    path: '/admin/user/:id',
    component: UserPage,
    inMenuBar: false,
    routeType: RouteType.ADMIN,
  },
  {
    menuBarTitle: 'Users',
    path: '/admin/add-user',
    component: AddUser,
    inMenuBar: false,
    routeType: RouteType.ADMIN,
  },
  {
    path: '/admin/edit-page/:client/:route',
    component: EditPage,
    inMenuBar: false,
    routeType: RouteType.ADMIN,
  },
  // Client Routes
  {
    menuBarTitle: 'Page',
    path: '/:client/:route',
    icon: <DashboardIcon />,
    component: Page,
    inMenuBar: false,
    routeType: RouteType.CLIENT,
  },
  {
    menuBarTitle: 'Page',
    path: '/',
    component: Dashboard,
    inMenuBar: false,
    routeType: RouteType.CLIENT,
  },
  {
    menuBarTitle: 'Home',
    path: '/dashboard',
    component: Dashboard,
    icon: <HomeIcon />,
    inMenuBar: true,
    routeType: RouteType.CLIENT,
  },
  // Public Routes
  {
    isPublic: true,
    menuBarTitle: 'Logout',
    path: '/logout',
    component: Logout,
    inMenuBar: true,
    routeType: RouteType.ALWAYS_SHOW,
    icon: <LogoutIcon />,
    testid: 'logoutLink',
  },
  {
    isPublic: true,
    menuBarTitle: 'Login',
    path: '/login',
    component: Login,
    inMenuBar: false,
    routeType: RouteType.CLIENT,
  },
  {
    isPublic: true,
    menuBarTitle: 'Login',
    path: '/verify/:email/:key',
    component: VerifyUser,
    inMenuBar: false,
    routeType: RouteType.CLIENT,
  },
  {
    isPublic: true,
    menuBarTitle: 'Login',
    path: '/reset-password',
    component: ResetPassword,
    inMenuBar: false,
    routeType: RouteType.CLIENT,
  },
];

// @ts-ignore
export const RoutesContext = React.createContext(ROUTES);
