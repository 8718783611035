import * as React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { ROUTES, RoutesContext, RouteType } from '../routes';
import NoMatch from './NoMatch';
import { AdminRoute, PrivateRoute } from '../components/PrivateRoute';
import { ApolloProvider, Query } from 'react-apollo';
import { HttpLink } from 'apollo-link-http';
import ApolloClient from 'apollo-client';
import gql from 'graphql-tag';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import ErrorBoundary from '../components/ErrorBoundary';
import introspectionQueryResultData from '../fragmentTypes.json';
import { queryHandle } from 'b6a-components/dist';

const fragmentMatcher = new IntrospectionFragmentMatcher({
  introspectionQueryResultData,
});

const cache = new InMemoryCache({
  fragmentMatcher,
  dataIdFromObject: (object: any) => `${object.__typename}-${object.id}`,
});

const client = new ApolloClient({
  cache,
  link: new HttpLink({
    uri: '/graphql',
    headers: {
      authorization: localStorage.getItem('token'),
    },
  }),
});

const GET_ME = gql`
  query getMe {
    me {
      id
      firstName
      lastName
      isAdmin
      clients {
        name
        slug
      }
    }
  }
`;

export const UserContext = React.createContext<LoggedInUser | undefined>(undefined);

interface LoggedInUser {
  me?: {
    firstName: string;
    lastName: string;
    isAdmin: boolean;
    clients: {
      name: string;
      slug: string;
    }[];
  };
}

class Index extends React.Component<{}, {}> {
  render() {
    return (
      <ErrorBoundary>
        <ApolloProvider client={client}>
          <RoutesContext.Provider value={ROUTES}>
            <Query<LoggedInUser> query={GET_ME}>
              {queryHandle((data: LoggedInUser | undefined) => {
                let isAuth = false;
                let isAdmin = false;

                if (data && data.me) {
                  isAuth = true;
                  isAdmin = data.me.isAdmin;
                }

                return (
                  <UserContext.Provider value={data}>
                    <Router>
                      <RoutesContext.Consumer>
                        {routes => (
                          <Switch>
                            {routes.map(route => {
                              if (route.isPublic) {
                                return (
                                  <Route
                                    path={route.path}
                                    key={route.path}
                                    exact
                                    component={route.component}
                                  />
                                );
                              }
                              if (route.routeType === RouteType.CLIENT) {
                                return (
                                  <PrivateRoute
                                    isAuthenticated={isAuth}
                                    path={route.path}
                                    key={route.path}
                                    exact
                                    component={route.component}
                                  />
                                );
                              }
                              if (route.routeType === RouteType.ADMIN) {
                                return (
                                  <AdminRoute
                                    isAuthenticated={isAuth}
                                    isAdmin={isAdmin}
                                    path={route.path}
                                    key={route.path}
                                    exact
                                    component={route.component}
                                  />
                                );
                              }
                            })}
                            <Route component={NoMatch} />
                          </Switch>
                        )}
                      </RoutesContext.Consumer>
                    </Router>
                  </UserContext.Provider>
                );
              })}
            </Query>
          </RoutesContext.Provider>
        </ApolloProvider>
      </ErrorBoundary>
    );
  }
}

export default Index;
