import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as yup from 'yup';
import { ErrorMessage, SuccessMessage, SaveButton, withRoot } from 'b6a-components/dist';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { Client, GetClient } from './index';

const EDIT_CLIENT = gql`
  mutation editClient($clientId: String!, $clientDetails: EditClientDetailsInput!) {
    editClientDetails(id: $clientId, clientDetails: $clientDetails) {
      id
      name
      slug
      pages {
        route
        menuBarTitle
        id
      }
      users {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

interface EditClientDetailProps extends WithStyles<typeof styles> {
  client: Client;
  handleClose: () => void;
}

interface EditClientMutationVariables {
  clientId: string;
  clientDetails: { slug: string; name: string };
}

interface EditClientValues {
  slug: string;
  name: string;
}

const styles = (theme: Theme) => ({
  buttonRow: {
    marginTop: theme.spacing.unit * 2,
  },
});

class EditClientDetailPanel extends React.Component<EditClientDetailProps> {
  validator = yup.object().shape({
    name: yup
      .string()
      .min(3)
      .required(),
    slug: yup
      .string()
      .min(4)
      .required()
      .matches(
        /^[a-z][0-9a-z\-]*$/,
        'Must start with a lower cased letter and may only ' +
          'contain lower cased alphanumeric or hypens.'
      ),
  });

  onSubmit(addClient: (values: { variables: EditClientMutationVariables }) => void) {
    return (values: EditClientValues) => {
      addClient({
        variables: {
          clientId: this.props.client.id,
          clientDetails: {
            slug: values.slug,
            name: values.name,
          },
        },
      });
    };
  }

  renderForm(client: Client, editClient: () => void, loading: boolean) {
    return (
      <Formik<EditClientValues>
        initialValues={client}
        validationSchema={this.validator}
        onSubmit={this.onSubmit(editClient)}
      >
        {({ errors, touched }) => {
          const isError = Object.keys(errors).length > 0;

          return (
            <Form>
              <DialogTitle id="form-dialog-title">Edit Client</DialogTitle>

              <DialogContent>
                <Field
                  label="Client Name"
                  type="text"
                  name="name"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />
                <Field
                  label="Slug"
                  type="text"
                  name="slug"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={this.props.handleClose} color="primary">
                  Cancel
                </Button>
                <SaveButton loading={loading} disabled={isError}>
                  Edit Client
                </SaveButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    );
  }

  render() {
    return (
      <Mutation<GetClient, EditClientMutationVariables> mutation={EDIT_CLIENT}>
        {(editClient, { loading, data, error }) => {
          const successMessage = (() => {
            if (!loading && !error && data) {
              return <SuccessMessage />;
            }
            return null;
          })();

          return (
            <>
              {successMessage}
              {error && (
                <ErrorMessage
                  header="Unable to Save"
                  message={`Error: ${error}`.replace('Error: GraphQL error: ', '')}
                />
              )}
              {this.renderForm(this.props.client, editClient, loading)}
            </>
          );
        }}
      </Mutation>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(EditClientDetailPanel));
