import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { Redirect } from 'react-router';
import Layout from '../components/Layout';
import { Grid, Theme, WithStyles, withStyles, ListItemText } from '@material-ui/core';
import ListItem from '@material-ui/core/ListItem';
import { Link } from 'react-router-dom';
import { Panel, queryHandle, withRoot } from 'b6a-components/dist';

const GET_PAGE = gql`
  query getMeClients {
    me {
      id
      isAdmin
      clients {
        id
        name
        slug
        pages {
          id
          route
        }
      }
    }
  }
`;

interface GetClientsResult {
  me: {
    isAdmin: boolean;
    clients: {
      id: string;
      name: string;
      slug: string;
      pages: {
        id: string;
        route: string;
      }[];
    }[];
  };
}

const styles = (theme: Theme) => ({
  primary: {
    color: theme.palette.text.primary,
  },
});

interface DashboardRouteProps extends WithStyles<typeof styles> {}

class Dashboard extends React.Component<DashboardRouteProps> {
  createLink = (to: string) => (props: any) => <Link key={to} to={to} {...props} />;

  render() {
    const { classes } = this.props;

    return (
      <Query<GetClientsResult> query={GET_PAGE}>
        {queryHandle(data => {
          if (!data) return <Redirect to="/login" />;

          const { clients, isAdmin } = data.me;

          if (isAdmin) {
            return (
              <Redirect
                to={{
                  pathname: '/admin',
                  state: { from: '/dashboard' },
                }}
              />
            );
          } else if (clients.length === 1) {
            return (
              <Redirect
                to={{
                  pathname: `/${clients[0].slug}/${clients[0].pages[0].route}`,
                  state: { from: '/dashboard' },
                }}
              />
            );
            // tslint:disable-next-line:no-else-after-return
          }

          // Users with Multiple clients
          // Last Updated: 06/17/20
          const scoreboards = clients
            .filter(client => client.pages.length > 0)
            .map(client => {
              return {
                name: client.name,
                slug: client.slug,
                path: `/${client.slug}/${client.pages[0].route}`,
              };
            });
          return (
            <Layout isAdmin={false}>
              <Grid container spacing={16}>
                <Panel size={6} color="RED" icon="domain_rounded" title="Scoreboards">
                  {scoreboards.map(scoreboard => {
                    return (
                      <ListItem
                        button
                        key={scoreboard.slug}
                        component={this.createLink(`${scoreboard.path}`)}
                      >
                        <ListItemText primary={scoreboard.name} classes={classes} />
                      </ListItem>
                    );
                  })}
                </Panel>
              </Grid>
            </Layout>
          );
        })}
      </Query>
    );
  }
}

export default withRoot(withStyles(styles)(Dashboard));
