import React from 'react';
import gql from 'graphql-tag';
import { Mutation, Query } from 'react-apollo';
import { Button, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import * as yup from 'yup';
import {
  ErrorMessage,
  SuccessMessage,
  SaveButton,
  queryHandle,
  withRoot,
} from 'b6a-components/dist';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogActions from '@material-ui/core/DialogActions';
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import { ICONS } from '../../../consts';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';

const GET_PAGES = gql`
  query getAllPages {
    pages {
      route
      menuBarTitle
      id
      client {
        id
        name
      }
    }
  }
`;

const ADD_PAGE_TO_CLIENT = gql`
  mutation addPageToClient($slug: String!, $pageDetails: NewPageDetailsInput!) {
    addPageToClient(slug: $slug, pageDetails: $pageDetails) {
      id
      name
      slug
      pages {
        route
        menuBarTitle
        id
      }
      users {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

const styles = (theme: Theme) => ({
  buttonRow: {
    marginTop: theme.spacing.unit * 2,
  },
});

interface GetPagesResult {
  pages: Page[];
}

interface Page {
  menuBarTitle: string;
  route: string;
  id: string;
  client: {
    name: string;
  };
}

interface AddPageMutationResult {
  addPageToClient?: {
    route: string;
    id: string;
  };
}

interface AddPageMutationVariables {
  slug: string;
  pageDetails: {
    title: string;
    menuBarTitle: string;
    menuBarIcon: string;
    route: string;
    inMenuBar: boolean;
    duplicate?: string;
  };
}

interface AddPageValues {
  title: string;
  menuBarTitle: string;
  menuBarIcon: string;
  route: string;
  duplicate?: string;
}

interface AddPageDialogProps extends WithStyles<typeof styles> {
  clientSlug: string;
  clientName: string;
  handleClose: () => void;
}

class AddPageDialog extends React.Component<AddPageDialogProps> {
  validator = yup.object().shape({
    route: yup
      .string()
      .label('Route')
      .min(5, 'Route must be 4 characters long')
      .strict(true)
      .matches(/^\//, 'Route must start with /')
      .lowercase()
      .required(),
    title: yup
      .string()
      .label('Page Title')
      .required(),
    menuBarTitle: yup
      .string()
      .label('Side Bar Title')
      .required(),
  });

  onSubmit(addClient: (values: { variables: AddPageMutationVariables }) => void) {
    const { clientSlug: slug } = this.props;

    return (values: AddPageValues) => {
      addClient({
        variables: {
          slug,
          pageDetails: {
            ...values,
            inMenuBar: true,
          },
        },
      });
    };
  }

  renderForm(pages: Page[], addClient: () => void, loading: boolean) {
    return (
      <Formik<AddPageValues>
        initialValues={{
          duplicate: 'none',
          title: '',
          menuBarTitle: '',
          menuBarIcon: '',
          route: '',
        }}
        validationSchema={this.validator}
        onSubmit={this.onSubmit(addClient)}
      >
        {({ errors, touched }) => {
          const isError = Object.keys(errors).length > 0 || Object.keys(touched).length !== 5;

          const sortedPages = pages.sort((a, b) => {
            if (a.client.name > b.client.name) return 1;
            if (a.client.name < b.client.name) return -1;

            if (a.menuBarTitle > b.menuBarTitle) return 1;
            if (a.menuBarTitle < b.menuBarTitle) return -1;
            return 0;
          });

          return (
            <Form>
              <DialogTitle id="form-dialog-title">Add Page to Client</DialogTitle>

              <DialogContent>
                <DialogContentText>Add a new page.</DialogContentText>

                <Field
                  label="Page Title"
                  type="text"
                  name="title"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />
                <Field
                  label="Menu Bar Title"
                  type="text"
                  name="menuBarTitle"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />

                <Field
                  label="Route"
                  type="text"
                  name="route"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />

                <FormControl required fullWidth margin="dense">
                  <InputLabel htmlFor="icon">Icon</InputLabel>
                  <Field
                    name="menuBarIcon"
                    component={Select}
                    fullWidth
                    inputProps={{
                      name: 'menuBarIcon',
                      id: 'menuBarIcon',
                    }}
                  >
                    {ICONS.map(v => (
                      <MenuItem value={v} key={`icons-${v}`}>
                        <Icon>{v}</Icon>
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>

                <FormControl required fullWidth margin="dense">
                  <InputLabel htmlFor="duplicate">Duplicate Page</InputLabel>
                  <Field
                    name="duplicate"
                    component={Select}
                    fullWidth
                    inputProps={{
                      name: 'duplicate',
                      id: 'duplicate',
                    }}
                  >
                    <MenuItem value={'none'}>None</MenuItem>
                    {sortedPages.map(page => (
                      <MenuItem value={page.id} key={`pages-values-${page.id}`}>
                        {page.client.name} - {page.menuBarTitle}
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </DialogContent>

              <DialogActions>
                <Button onClick={this.props.handleClose} color="primary">
                  Cancel
                </Button>
                <SaveButton loading={loading} disabled={isError}>
                  Add Page
                </SaveButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    );
  }

  render() {
    return (
      <Mutation<AddPageMutationResult, AddPageMutationVariables> mutation={ADD_PAGE_TO_CLIENT}>
        {(addPage, { loading, data, error }) => {
          const successMessage = (() => {
            if (!loading && !error && data && data.addPageToClient) {
              return <SuccessMessage />;
            }
            return null;
          })();

          return (
            <>
              {successMessage}
              {error && (
                <ErrorMessage
                  header="Unable to Save"
                  message={`Error: ${error}`.replace('Error: GraphQL error: ', '')}
                />
              )}
              <Query<GetPagesResult> query={GET_PAGES}>
                {queryHandle((data: GetPagesResult | undefined) => {
                  if (data) {
                    return this.renderForm(data.pages, addPage, loading);
                  }
                })}
              </Query>
            </>
          );
        }}
      </Mutation>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(AddPageDialog));
