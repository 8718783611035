import Layout from '../components/Layout';
import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Grid from '@material-ui/core/Grid';
import { RouteComponentProps } from 'react-router';
import Typography from '@material-ui/core/Typography';
import { Link } from 'react-router-dom';
import { Panel, ErrorMessage, SuccessMessage, SaveButton, withRoot } from 'b6a-components/dist';

const PASSWORD_REGEX = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})');
const VERIFY_USER = gql`
  mutation verifyUser($email: String!, $oldPassword: String!, $newPassword: String!) {
    verifyUser(email: $email, oldPassword: $oldPassword, newPassword: $newPassword) {
      id
    }
  }
`;

const styles = (theme: Theme) => ({
  buttonRow: {
    marginTop: theme.spacing.unit * 2,
  },
});

interface VerifyUserMutation {
  verifyUser?: {
    id: string;
  };
}

interface VerifyUserVariables {
  email: string;
  oldPassword: string;
  newPassword: string;
}

interface VerifyUserValues {
  newPassword: string;
  confirmPassword: string;
}

interface VerifyUserRouteProps {
  email: string;
  key: string;
}

interface VerifyUserProps extends WithStyles<typeof styles> {}

class VerifyUserPage extends React.Component<
  RouteComponentProps<VerifyUserRouteProps> & VerifyUserProps
> {
  onSubmit(verifyUser: (values: { variables: VerifyUserVariables }) => void) {
    const { email, key } = this.props.match.params;
    return (values: VerifyUserValues) => {
      verifyUser({
        variables: {
          email: decodeURIComponent(email),
          oldPassword: key,
          newPassword: values.newPassword,
        },
      });
    };
  }

  renderForm(addClient: () => void, loading: boolean) {
    const { classes } = this.props;
    const { email } = this.props.match.params;

    const decoded = decodeURIComponent(email);

    return (
      <Formik<VerifyUserValues>
        initialValues={{ newPassword: '', confirmPassword: '' }}
        validate={(values: VerifyUserValues) => {
          const pwResult = PASSWORD_REGEX.test(values.newPassword);

          if (!pwResult) {
            const message = 'Your new password does not meet the password requirements.';

            return {
              newPassword: message,
            };
          }
          if (values.newPassword !== values.confirmPassword) {
            return { confirmPassword: 'Passwords do not match' };
          }
        }}
        onSubmit={this.onSubmit(addClient)}
      >
        {({ resetForm, errors }) => {
          const isError = Object.keys(errors).length > 0;

          return (
            <Form>
              <Typography gutterBottom={true}>Email: {decoded}</Typography>
              <Field
                label="New Password"
                type="password"
                name="newPassword"
                fullWidth
                required
                autoComplete="off"
                component={TextField}
              />
              <Field
                label="Confirm Password"
                type="password"
                name="confirmPassword"
                fullWidth
                required
                autoComplete="off"
                component={TextField}
              />

              <div className={classes.buttonRow}>
                <SaveButton loading={loading} disabled={isError}>
                  Verify
                </SaveButton>
                <Button onClick={() => resetForm()}>Reset</Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  render() {
    return (
      <Layout isAdmin={true}>
        <Grid container={true} justify="center">
          <Panel size={6} color="GREEN" icon="person_rounded" title="Verify Your Email">
            <Mutation<VerifyUserMutation, VerifyUserVariables> mutation={VERIFY_USER}>
              {(addClient, { loading, data, error }) => {
                if (!loading && !error && data && data.verifyUser) {
                  const LoginLink = (props: any) => <Link to="/login" {...props} />;
                  return (
                    <SuccessMessage header="Success">
                      <Typography>
                        You have been successfully verified.
                        <br />
                        <Button component={LoginLink} color="primary" variant="contained">
                          Login Here
                        </Button>
                      </Typography>
                    </SuccessMessage>
                  );
                }
                return (
                  <>
                    <Typography gutterBottom={true}>
                      Requirements for new passwords:
                      <br />* Password must contain at least 1 lowercase alphabetical character.
                      <br />* Password must contain at least 1 uppercase alphabetical character.
                      <br />* Password must contain at least 1 numeric character.
                      <br />* Password must contain at least one of the following special
                      characters: !@#$%^&*.
                      <br />* Password must be eight characters or longer.
                    </Typography>

                    {error && <ErrorMessage header="Unable to Save" message={error.message} />}
                    {this.renderForm(addClient, loading)}
                  </>
                );
              }}
            </Mutation>
          </Panel>
        </Grid>
      </Layout>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(VerifyUserPage));
