import React from 'react';
import classNames from 'classnames';
import { Theme, WithStyles, withStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import List from '@material-ui/core/List';
import IconButton from '@material-ui/core/IconButton';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from './ListItemText';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeftRounded';
import ChevronRightIcon from '@material-ui/icons/ChevronRightRounded';
import { queryHandle, withRoot } from 'b6a-components/dist';
import { RoutesContext, RouteType } from '../../routes';
import { Link } from 'react-router-dom';
import { cyan } from '@material-ui/core/colors';
import { Query } from 'react-apollo';
import graphql from 'graphql-tag';
import { Icon } from '@material-ui/core';
import Divider from '@material-ui/core/Divider';

export const CLIENT_ROUTES = graphql`
  query routes($slug: String!) {
    client(slug: $slug) {
      id
      pages {
        id
        route
        menuBarIcon
        menuBarTitle
        inMenuBar
      }
    }
  }
`;

interface ClientRoutesQuery {
  client?: {
    pages: {
      route: string;
      menuBarIcon: string;
      menuBarTitle: string;
      inMenuBar: boolean;
    }[];
  };
}

const drawerWidth = 240;

const styles = (theme: Theme) => ({
  root: {
    display: 'flex',
  },
  primary: {
    color: '#fff',
  },
  listItem: {
    '&:hover': {
      backgroundColor: cyan[700],
    },
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginLeft: 12,
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
    fontWeight: 400,
  },
  drawerPaper: {
    position: 'relative' as 'relative',
    whiteSpace: 'nowrap' as 'nowrap',
    width: drawerWidth,
    backgroundColor: '#212121',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
    zIndex: 100,
  },
  drawerPaperClose: {
    overflowX: 'hidden' as 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing.unit * 7,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 9,
    },
  },
  listItemIcon: {
    color: '#fff',
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
    minHeight: '100vh',
    backgroundColor: '#eee',
    width: 'calc(100% - 240px)',
  },
  chartContainer: {
    marginLeft: -22,
  },
  tableContainer: {
    height: 320,
  },
  h5: {
    marginBottom: theme.spacing.unit * 2,
  },
  pos: {
    marginBottom: 12,
  },
  divider: {
    backgroundColor: 'rgba(197, 197, 197, 0.68)',
  },
});

type State = {
  open: boolean;
};

interface MenuBarRoute {
  path: string;
  icon?: React.ReactElement<any> | string;
  menuBarTitle?: string;
  testid?: string;
}

interface LayoutProps extends WithStyles<typeof styles> {
  isAdmin: boolean;
  currentClient?: string;
  showClientRoutes?: boolean;
}

class Layout extends React.Component<LayoutProps, State> {
  constructor(props: LayoutProps) {
    super(props);

    const drawerOpen = localStorage.getItem('drawerState');
    let drawerState = true;

    if (drawerOpen === 'closed') {
      drawerState = false;
    }

    this.state = {
      open: drawerState,
    };
  }

  handleDrawerOpen = () => {
    localStorage.setItem('drawerState', 'open');
    this.setState({ open: true });
  };

  handleDrawerClose = () => {
    localStorage.setItem('drawerState', 'closed');
    this.setState({ open: false });
  };

  createLink = (to: string) => (props: any) => <Link to={to} {...props} />;

  routeRender(routes: MenuBarRoute[]) {
    const { classes } = this.props;

    return routes.map(route => (
      <ListItem
        className={classes.listItem}
        button
        key={route.path}
        component={this.createLink(route.path)}
        data-testid={route.testid}
      >
        <ListItemIcon className={classes.listItemIcon}>
          <>
            {typeof route.icon === 'string' && <Icon>{route.icon}</Icon>}
            {typeof route.icon === 'object' && route.icon}
          </>
        </ListItemIcon>
        <ListItemText text={route.menuBarTitle ? route.menuBarTitle : ''} />
      </ListItem>
    ));
  }

  render() {
    const { classes, isAdmin } = this.props;

    // Drawer is open
    let drawerIcon = (
      <IconButton onClick={this.handleDrawerClose} id="close-drawer">
        <ChevronLeftIcon className={classes.listItemIcon} />
      </IconButton>
    );

    if (!this.state.open) {
      drawerIcon = (
        <IconButton onClick={this.handleDrawerOpen} id="open-drawer">
          <ChevronRightIcon className={classes.listItemIcon} />
        </IconButton>
      );
    }

    let routeType = RouteType.CLIENT;
    if (isAdmin) {
      routeType = RouteType.ADMIN;
    }

    return (
      <div className={classes.root}>
        <CssBaseline />

        <Drawer
          variant="permanent"
          classes={{
            paper: classNames(classes.drawerPaper, !this.state.open && classes.drawerPaperClose),
          }}
          open={this.state.open}
        >
          <div className={classes.toolbarIcon}>{drawerIcon}</div>
          <List component="nav">
            {routeType === RouteType.ADMIN && (
              <RoutesContext.Consumer>
                {routes => (
                  <>
                    {this.routeRender(
                      routes.filter(route => route.inMenuBar && route.routeType === routeType)
                    )}
                  </>
                )}
              </RoutesContext.Consumer>
            )}
            {this.props.showClientRoutes && <Divider className={classes.divider} />}
            {routeType === RouteType.CLIENT && (
              <RoutesContext.Consumer>
                {routes => (
                  <>
                    {this.routeRender(
                      routes.filter(route => route.inMenuBar && route.routeType === routeType)
                    )}
                  </>
                )}
              </RoutesContext.Consumer>
            )}
            {(routeType === RouteType.CLIENT || this.props.showClientRoutes) &&
              this.props.currentClient && (
                <Query<ClientRoutesQuery>
                  query={CLIENT_ROUTES}
                  variables={{ slug: this.props.currentClient }}
                >
                  {queryHandle(data => {
                    if (data && data.client) {
                      const routes = data.client.pages
                        .filter(p => p.inMenuBar)
                        .map(page => {
                          return {
                            path: page.route,
                            routeType: RouteType.CLIENT,
                            menuBarTitle: page.menuBarTitle,
                            icon: page.menuBarIcon,
                          };
                        });
                      return this.routeRender(routes);
                    }
                    return null;
                  })}
                </Query>
              )}
            {this.props.showClientRoutes && <Divider className={classes.divider} />}
            <RoutesContext.Consumer>
              {routes => (
                <>
                  {this.routeRender(
                    routes.filter(
                      route => route.inMenuBar && route.routeType === RouteType.ALWAYS_SHOW
                    )
                  )}
                </>
              )}
            </RoutesContext.Consumer>
          </List>
        </Drawer>

        <main className={classes.content}>{this.props.children}</main>
      </div>
    );
  }
}

export default withRoot(withStyles(styles)(Layout));
