import React, { Component } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DialogProps } from '../..';
import ImageUploader from '../../../ImageUploader';

class AddImageDialog extends Component<DialogProps> {
  url?: string;

  onSubmit() {
    const { editor, onClose } = this.props;

    if (editor) {
      editor.insertBlock({
        type: 'image',
        data: { src: this.url, attrs: { width: '100%' } },
      });
    }

    onClose('add-image');
  }

  onSuccess(url: string) {
    this.url = url;
  }

  render() {
    return (
      <>
        <DialogTitle>Insert an Image</DialogTitle>
        <DialogContent>
          <DialogContentText>Select your file</DialogContentText>

          <ImageUploader onSuccess={this.onSuccess.bind(this)} />
        </DialogContent>

        <DialogActions>
          <Button
            data-testid="dialogCancel"
            onClick={() => this.props.onClose('cancel')}
            color="primary"
          >
            Cancel
          </Button>
          <Button
            onClick={this.onSubmit.bind(this)}
            data-testid="dialogAccept"
            type="submit"
            variant="contained"
            color="primary"
          >
            Insert
          </Button>
        </DialogActions>
      </>
    );
  }
}

export default AddImageDialog;
