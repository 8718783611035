import Layout from '../../components/Layout';
import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Grid from '@material-ui/core/Grid';
import * as yup from 'yup';
import { Panel, ErrorMessage, SuccessMessage, SaveButton, withRoot } from 'b6a-components/dist';

const ADD_CLIENT = gql`
  mutation addClient($slug: String!, $name: String!) {
    newClient(name: $name, slug: $slug) {
      id
      slug
    }
  }
`;

const styles = (theme: Theme) => ({
  buttonRow: {
    marginTop: theme.spacing.unit * 2,
  },
});

interface AddClientMutation {
  newClient?: {
    slug: string;
  };
}

interface AddClientsVariables {}

interface AddClientValues {
  slug: string;
  name: string;
}

interface ClientsProps extends WithStyles<typeof styles> {}

class AddClientPage extends React.Component<ClientsProps> {
  onSubmit(addClient: (values: any) => void) {
    return (values: AddClientValues) => {
      addClient({
        variables: values,
      });
    };
  }

  renderForm(addClient: () => void, loading: boolean, success: boolean) {
    const { classes } = this.props;

    return (
      <Formik<AddClientValues>
        initialValues={{ slug: '', name: '' }}
        validationSchema={this.validator}
        onSubmit={this.onSubmit(addClient)}
      >
        {({ resetForm, errors, touched }) => {
          const isError = Object.keys(errors).length > 0 || Object.keys(touched).length !== 2;

          return (
            <Form>
              <Field
                label="Client Name"
                helperText="This is the name of the client. It may contain any characters."
                type="text"
                name="name"
                fullWidth
                required
                autoComplete="off"
                component={TextField}
              />
              <Field
                label="Client Slug"
                helperText="This is the value that shows up in the address bar.
              It must be all lower-cased alphanumeric
              characters or hyphens, and may not contain spaces, slashes or symbols."
                type="text"
                name="slug"
                fullWidth
                required
                autoComplete="off"
                component={TextField}
              />

              <div className={classes.buttonRow}>
                <SaveButton loading={loading} disabled={isError}>
                  Create
                </SaveButton>
                <Button onClick={() => resetForm()}>Reset</Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  validator = yup.object().shape({
    name: yup
      .string()
      .min(3)
      .required(),
    slug: yup
      .string()
      .min(4)
      .required()
      .matches(
        /^[a-z][0-9a-z\-]*$/,
        'Must start with a lower cased letter and may only ' +
          'contain lower cased alphanumeric or hypens.'
      ),
  });

  render() {
    return (
      <Layout isAdmin={true}>
        <Grid>
          <Panel size={6} icon="domain_sharp" title="Create New Client">
            <Mutation<AddClientMutation, AddClientsVariables> mutation={ADD_CLIENT}>
              {(addClient, { loading, data, error }) => {
                return (
                  <>
                    {error && (
                      <ErrorMessage
                        header="Unable to Save"
                        message={`Error: ${error}`.replace('Error: GraphQL error: ', '')}
                      />
                    )}
                    {!loading && !error && data && data.newClient && (
                      <SuccessMessage message="Client added successfully" />
                    )}
                    {this.renderForm(addClient, loading, data !== undefined && data !== {})}
                  </>
                );
              }}
            </Mutation>
          </Panel>
        </Grid>
      </Layout>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(AddClientPage));
