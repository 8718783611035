import { Redirect, Route } from 'react-router';
import * as React from 'react';
import NoMatch from '../../pages/NoMatch';

interface PrivateRouteProps {
  component: any;
  isAuthenticated: boolean;

  [propName: string]: any;
}

interface AdminRouteProps {
  component: any;
  isAdmin: boolean;
  isAuthenticated: boolean;

  [propName: string]: any;
}

export function PrivateRoute({
  isAuthenticated,
  component: Component,
  ...rest
}: PrivateRouteProps) {
  return (
    <Route
      {...rest}
      render={props =>
        isAuthenticated ? (
          <Component {...props} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        )
      }
    />
  );
}

export function AdminRoute({
  isAdmin,
  isAuthenticated,
  component: Component,
  ...rest
}: AdminRouteProps) {
  return (
    <Route
      {...rest}
      render={props => {
        if (isAdmin) {
          return <Component {...props} />;
        } else if (isAuthenticated) {
          return <NoMatch />;
        }

        return (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: props.location },
            }}
          />
        );
      }}
    />
  );
}
