import React, { Component } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DialogProps } from '../..';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import graphql from 'graphql-tag';
import { Block } from 'slate';
import { ApolloConsumer } from 'react-apollo';
import Typography from '@material-ui/core/Typography';
import { ErrorMessage, SuccessMessage } from 'b6a-components/dist';

export const GET_TABLE_DATA = graphql`
  query table($tableId: String!) {
    table(tableId: $tableId) {
      sheets {
        title
        values
      }
    }
  }
`;

export interface GetTableData {
  data: {
    table?: {
      sheets: {
        title: string;
        values: string[][];
      }[];
    };
  };
}

interface SetTableIdProps extends DialogProps {
  node: Block;
}

interface SetTableIdState {
  message?: string;
  resultType?: 'success' | 'error';
}

class SetTableId extends Component<SetTableIdProps, SetTableIdState> {
  state = {
    message: undefined,
    resultType: undefined,
  };

  showMessage() {
    const { resultType, message } = this.state;

    if (resultType && message) {
      switch (resultType) {
        case 'success':
          return <SuccessMessage message={message} />;
        case 'error':
          return <ErrorMessage header="Unable to load data" message={message} />;
      }
    }

    return null;
  }

  render() {
    const { onClose } = this.props;

    return (
      <ApolloConsumer>
        {client => (
          <Formik
            initialValues={{ url: '' }}
            onSubmit={async (values, { setSubmitting }) => {
              const { url } = values;
              const tableId = url.split('/')[5];

              try {
                const result: GetTableData = await client.query({
                  query: GET_TABLE_DATA,
                  variables: { tableId },
                });

                setSubmitting(false);

                if (result.data && result.data.table && this.props.editor) {
                  const { node } = this.props;
                  this.setState({
                    message: 'Data was successfully loaded!',
                    resultType: 'success',
                  });

                  let newNode = node.setIn(['data', 'tableData'], result.data.table) as Block;
                  newNode = newNode.setIn(['data', 'tableId'], tableId) as Block;
                  this.props.editor.replaceNodeByKey(node.key, newNode);

                  window.setTimeout(() => onClose('set-table'), 2000);
                } else {
                  this.setState({
                    message:
                      'Unable to fetch data. Please double check ' +
                      'the url and make sure that ' +
                      'skynet@scoreboard-230920.iam.gserviceaccount.com has been given ' +
                      'access to the sheet',
                    resultType: 'error',
                  });
                }
              } catch (e) {
                this.setState({
                  message: 'Unable to fetch data due to server issue. Please try again later',
                  resultType: 'error',
                });
              }
            }}
          >
            {({ isSubmitting }) => (
              <Form>
                <DialogTitle>Insert a Google Sheet</DialogTitle>
                <DialogContent>
                  <DialogContentText>Paste the URL of the Google Sheet</DialogContentText>
                </DialogContent>

                {isSubmitting ? (
                  <Typography component="div">Fetching Data...</Typography>
                ) : (
                  this.showMessage()
                )}

                <Field type="text" name="url" fullWidth component={TextField} />
                <DialogActions>
                  <Button
                    data-testid="dialogCancel"
                    onClick={() => this.props.onClose('cancel')}
                    color="primary"
                  >
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    data-testid="dialogAccept"
                    type="submit"
                    color="primary"
                  >
                    Insert
                  </Button>
                </DialogActions>
              </Form>
            )}
          </Formik>
        )}
      </ApolloConsumer>
    );
  }
}

export default SetTableId;
