export const ICONS: string[] = [
  'account_circle_rounded',
  'account_balance_rounded',
  'announcement_rounded',
  'forum_rounded',
  'attach_money_rounded',
  'assessment_rounded',
  'backup_rounded',
  'dashboard_rounded',
  'pie_chart_rounded',
  'people_rounded',
  'insert_photo_rounded',
  'table_chart_rounded',
  'home_rounded',
  'watch_later_rounded',
  'search_rounded',
  'settings_rounded',
  'thumbs_up_down_rounded',
  'domain_rounded',
  'computer_rounded',
  'tv_rounded',
  'photo_size_select_small_rounded',
  'grid_on_rounded',
  'favorite_rounded',
  'sentiment_dissatisfied',
  'sentiment_satisfied',
  'sentiment_very_dissatisfied',
  'sentiment_very_satisfied',
];
