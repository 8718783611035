import React from 'react';
import { Theme, withStyles, WithStyles, ListItemText } from '@material-ui/core';
import { withRoot } from 'b6a-components/dist';

const styles = (theme: Theme) => ({
  primary: {
    color: '#fff',
  },
});

interface ListItemTextComponentProps {
  text: string;
}

class ListItemTextComponent extends React.Component<
  WithStyles<typeof styles> & ListItemTextComponentProps,
  {}
> {
  render() {
    const { classes, text } = this.props;
    return <ListItemText primary={text} classes={classes} />;
  }
}

export default withRoot(withStyles(styles)(ListItemTextComponent));
