import React from 'react';
import Avatar from '@material-ui/core/Avatar';
import CssBaseline from '@material-ui/core/CssBaseline';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import withStyles, { WithStyles } from '@material-ui/core/styles/withStyles';
import { Mutation } from 'react-apollo';
import { Theme } from '@material-ui/core';
import { Field, Form, Formik, FormikProps } from 'formik';
import gql from 'graphql-tag';
import { Link, RouteComponentProps } from 'react-router-dom';
import { TextField } from 'formik-material-ui';
import { ErrorMessage, SaveButton, withRoot } from 'b6a-components/dist';

const styles = (theme: Theme) => ({
  main: {
    width: 'auto',
    display: 'block', // Fix IE 11 issue.
    marginLeft: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit * 3,
    [theme.breakpoints.up(400 + theme.spacing.unit * 3 * 2)]: {
      width: 400,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing.unit * 8,
    display: 'flex',
    flexDirection: 'column' as 'column',
    alignItems: 'center',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px`,
  },
  avatar: {
    margin: theme.spacing.unit,
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit,
  },
  submit: {
    marginTop: theme.spacing.unit * 3,
  },
});

const LOGIN_USER = gql`
  mutation login($email: String!, $password: String!) {
    login(email: $email, password: $password)
  }
`;

interface LoginResponse {
  login: string | null;
}

interface LoginFormValues {
  email: string;
  password: string;
}

interface LoginFormErrors {
  email?: string;
}

interface LoginFormState {
  hasSubmitted: boolean;
}

class SignIn extends React.Component<
  WithStyles<typeof styles & RouteComponentProps>,
  LoginFormState
> {
  setSubmittingFn: ((isSubmitting: boolean) => void) | undefined = undefined;
  clearFormFn: ((nextValues?: LoginFormValues) => void) | undefined = undefined;

  constructor(props: any) {
    super(props);
    this.state = { hasSubmitted: false };
  }

  render() {
    const { classes } = this.props;
    const { hasSubmitted } = this.state;

    return (
      <main className={classes.main}>
        <CssBaseline />
        <Paper className={classes.paper}>
          <Avatar className={classes.avatar}>
            <LockOutlinedIcon />
          </Avatar>
          <Typography component="h1" variant="h5">
            Sign in
          </Typography>
          {hasSubmitted && (
            <ErrorMessage
              header="Login Failed"
              message="Either email and/or password was incorrect."
              testId="loginFailedMessage"
            />
          )}
          <Mutation
            mutation={LOGIN_USER}
            onCompleted={({ login }: LoginResponse) => {
              this.setSubmittingFn && this.setSubmittingFn(false);

              if (login) {
                localStorage.setItem('token', login);
                window.location.href = '/dashboard';
              } else {
                this.setState({ hasSubmitted: true });
                this.clearFormFn && this.clearFormFn();
              }
            }}
          >
            {(login, { client }) => {
              const ResetLink = (props: any) => <Link to="/reset-password" {...props} />;
              client.clearStore();

              return (
                <>
                  <Formik<LoginFormValues>
                    initialValues={{ email: '', password: '' }}
                    validate={this.validator}
                    onSubmit={(values, { setSubmitting, resetForm }) => {
                      this.setSubmittingFn = setSubmitting;
                      this.clearFormFn = resetForm;
                      login({ variables: values });
                    }}
                  >
                    {this.renderForm.bind(this)}
                  </Formik>
                  <Typography component={ResetLink}>Forgot Password?</Typography>
                </>
              );
            }}
          </Mutation>
        </Paper>
      </main>
    );
  }

  validator(values: LoginFormValues): LoginFormErrors {
    const errors: { email?: string } = {};

    if (!values.email) {
      errors.email = 'Required';
    } else if (!/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i.test(values.email)) {
      errors.email = 'Invalid email address';
    }

    return errors;
  }

  renderForm({ isSubmitting, errors }: FormikProps<LoginFormValues>) {
    const { classes } = this.props;

    return (
      <div className={classes.form}>
        <Form>
          <Field
            data-testid="loginEmail"
            type="email"
            label="Email"
            name="email"
            component={TextField}
            fullWidth
          />

          <Field
            data-testid="loginPassword"
            type="password"
            label="Password"
            name="password"
            component={TextField}
            fullWidth
          />

          <div className={classes.submit}>
            <SaveButton
              fullWidth
              loading={isSubmitting}
              disabled={isSubmitting || Object.keys(errors).length > 0}
              data-testid="loginSubmit"
            >
              Sign in
            </SaveButton>
          </div>
        </Form>
      </div>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(SignIn));
