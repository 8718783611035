import React from 'react';
import Editor from '../Editor';
import { Value } from 'slate';
import { Panel as PanelComponent } from 'b6a-components/dist';
import { Grid } from '@material-ui/core';
import { GridSize } from '@material-ui/core/Grid';
import { DialogProps } from '../PageEditor';

export interface PanelInterface {
  id: string;
  order: number;
  size: number;
  panelType: string;
  document: Value;
  editable?: boolean;
  callback?: (payload: { type: string; value?: any }) => void;
  dialog?: (dialog: React.ReactElement<DialogProps>) => void;
  testId: string;
}

export interface TextPanel extends PanelInterface {
  color: string;
  icon: string;
  title: string;
}

export interface StatsPanel extends PanelInterface {
  color: string;
  icon: string;
  stat: string;
  value: string;
}

export interface PhotoPanel extends PanelInterface {
  imageUrl: string;
}

export type Panel = TextPanel | StatsPanel | PhotoPanel;

interface PageComponentProps {
  panels: Panel[];
  dialog?: (dialog: React.ReactElement<DialogProps>) => void;
  dialogClose?: () => void;
  client: string;
}

class PageComponent extends React.Component<PageComponentProps> {
  // Render the editor.
  render() {
    const { panels } = this.props;

    return (
      <Grid container spacing={32}>
        {panels &&
          panels.map((p, index) => {
            switch (p.panelType) {
              case 'text': {
                const panel = p as TextPanel;
                return (
                  <PanelComponent
                    selected={false}
                    key={panel.id !== '0' ? panel.id : `new-panel-${index}`}
                    size={panel.size as GridSize}
                    icon={panel.icon}
                    color={panel.color}
                    title={panel.title}
                  >
                    <Editor
                      value={panel.document}
                      readOnly={!panel.editable}
                      callback={panel.callback}
                      panelProps={panel}
                      dialog={this.props.dialog}
                      dialogClose={this.props.dialogClose}
                      clientSlug={this.props.client}
                    />
                  </PanelComponent>
                );
              }
              case 'stat': {
                const panel = p as StatsPanel;
                return (
                  <PanelComponent
                    selected={false}
                    key={panel.id !== '0' ? panel.id : `new-panel-${index}`}
                    size={panel.size as GridSize}
                    icon={panel.icon}
                    color={panel.color}
                    stat={panel.stat}
                    value={panel.value}
                  >
                    <Editor
                      value={panel.document}
                      readOnly={!panel.editable}
                      callback={panel.callback}
                      panelProps={panel}
                      dialog={this.props.dialog}
                      dialogClose={this.props.dialogClose}
                      clientSlug={this.props.client}
                    />
                  </PanelComponent>
                );
              }

              case 'photo': {
                const panel = p as PhotoPanel;
                return (
                  <PanelComponent
                    selected={false}
                    key={panel.id !== '0' ? panel.id : `new-panel-${index}`}
                    size={panel.size as GridSize}
                    imageUrl={panel.imageUrl}
                  >
                    <Editor
                      value={panel.document}
                      readOnly={!panel.editable}
                      callback={panel.callback}
                      panelProps={panel}
                      dialog={this.props.dialog}
                      dialogClose={this.props.dialogClose}
                      clientSlug={this.props.client}
                    />
                  </PanelComponent>
                );
              }
            }
          })}
      </Grid>
    );
  }
}

export default PageComponent;
