import * as React from 'react';
import * as ReactDOM from 'react-dom';
import Index from './pages';
import * as Sentry from '@sentry/browser';
import jwt_decode from 'jwt-decode';

interface UserData {
  email: string;
  id: string;
}

const shortSha = process.env.REACT_APP_SHA ? process.env.REACT_APP_SHA.slice(0, 8) : 'unknown';

Sentry.init({
  dsn: 'https://23dcd8693dd744e89126233dc173c8d5@sentry.io/1413683',
  environment: process.env.REACT_APP_STAGE,
  release: shortSha,
});

const user = localStorage.getItem('token');

if (user) {
  const userData: UserData = jwt_decode(user);
  Sentry.configureScope(scope => {
    scope.setUser(userData);
  });

  // @ts-ignore
  FS.identify(userData.userId, userData);

  Sentry.addBreadcrumb({ message: 'loaded page' });
} else {
  Sentry.addBreadcrumb({ message: 'loaded without being logged in' });
}

ReactDOM.render(<Index />, document.querySelector('#root'));
