import Layout from '../../components/Layout';
import React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import NoMatch from '../NoMatch';
import { RouteComponentProps } from 'react-router';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import MaterialTable from 'material-table';
import { Theme, WithStyles, withStyles } from '@material-ui/core';
import { Link } from 'react-router-dom';
import EditIcon from '@material-ui/icons/EditRounded';
import { Panel, queryHandle, withRoot } from 'b6a-components/dist';

const GET_CLIENTS = gql`
  query {
    clients {
      name
      slug
      id
    }
  }
`;

const styles = (theme: Theme) => ({});

interface Client {
  name: string;
  slug: string;
  id: string;
}

interface GetClients {
  clients?: Client[];
}

interface GetClientsVariables {}

interface ClientsRouteProps {}
interface ClientsProps extends WithStyles<typeof styles> {}

const ClientPageLink = (slug: string) => (props: any) => (
  <Link to={`/admin/client/${slug}`} {...props} />
);

const COLUMNS = [
  {
    title: '',
    field: 'id',
    filtering: false,
    cellStyle: { width: 20 },
    render: (rowData: Client & { tableData: { id: string } }) => (
      <>
        <IconButton component={ClientPageLink(rowData.slug)}>
          <EditIcon />
        </IconButton>
      </>
    ),
  },
  { title: 'ID', field: 'id' },
  { title: 'Name', field: 'name' },
  { title: 'Slug', field: 'slug' },
];

class ClientsPage extends React.Component<RouteComponentProps<ClientsRouteProps> & ClientsProps> {
  // Render the editor.
  render() {
    return (
      <Query<GetClients, GetClientsVariables> query={GET_CLIENTS}>
        {queryHandle((data: GetClients | undefined) => {
          if (data) {
            const { clients } = data;

            return (
              <Layout isAdmin={true}>
                <Grid container spacing={16}>
                  <Panel size={12} color="RED" icon="domain_rounded" title="Clients">
                    {clients ? (
                      <MaterialTable
                        columns={COLUMNS}
                        data={clients}
                        title=""
                        options={{
                          columnsButton: true,
                          filtering: true,
                          pageSize: 10,
                          pageSizeOptions: [10, 15, 20],
                        }}
                      />
                    ) : (
                      'no clients found'
                    )}
                  </Panel>
                </Grid>
              </Layout>
            );
          }
          return <NoMatch />;
        })}
      </Query>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(ClientsPage));
