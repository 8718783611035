import React, { Component } from 'react';
import ReactS3Uploader from 'react-s3-uploader';
import uuid from 'uuid';
import { ApolloConsumer } from 'react-apollo';
import graphql from 'graphql-tag';
import ApolloClient from 'apollo-client';
import LinearProgress from '@material-ui/core/LinearProgress';

interface ImageUploaderProps {
  onSuccess: (url: string) => void;
  imageUrl?: string;
}
interface ImageUploaderState {
  progress: number;
  imageUrl?: string;
}

const SIGN_UPLOAD = graphql`
  mutation signUpload($params: SignUploadInput!) {
    signUpload(params: $params)
  }
`;

class ImageUploader extends Component<ImageUploaderProps, ImageUploaderState> {
  // States:
  // - No image selected
  // - Image selected but not uploading to s3
  // - Uploading to s3
  // -- Successfully uploaded
  // -- Failed upload

  constructor(props: ImageUploaderProps) {
    super(props);

    this.state = {
      progress: 0,
      imageUrl: props.imageUrl,
    };
  }

  filename?: string;

  onUploadFinish() {
    if (this.filename) {
      const filename = `https://d37qilqfvsr068.cloudfront.net/${this.filename}`;
      this.props.onSuccess(filename);
      this.setState({ imageUrl: filename });
    }
  }

  scrubFilename(filename: string): string {
    const extension = filename.split('.').reverse()[0];
    const newFilename = `${uuid.v4()}.${extension}`;
    this.filename = newFilename;
    return newFilename;
  }

  getSignedUrl = (client: ApolloClient<any>) => (
    file: { name: string; type: string },
    callback: (data: { signedUrl: string }) => void
  ) => {
    const params = {
      objectName: this.scrubFilename(file.name),
      contentType: file.type,
    };

    client
      .mutate({
        mutation: SIGN_UPLOAD,
        variables: { params },
        fetchPolicy: 'no-cache',
      })
      .then((data: { data: { signUpload: string } }) => {
        callback({ signedUrl: data.data.signUpload });
      })
      .catch((error: any) => {
        console.error(error);
      });
  };

  onProgress(progress: number) {
    this.setState({ progress });
  }

  render() {
    return (
      <ApolloConsumer>
        {client => (
          <>
            {this.state.progress > 0 && (
              <LinearProgress variant="determinate" value={this.state.progress} />
            )}
            <ReactS3Uploader
              getSignedUrl={this.getSignedUrl(client)}
              accept="image/*"
              onFinish={this.onUploadFinish.bind(this)}
              onProgress={this.onProgress.bind(this)}
              contentDisposition="auto"
              scrubFilename={this.scrubFilename}
              uploadRequestHeaders={{
                'x-amz-acl': 'public-read',
              }}
              autoUpload={true}
            />
            {this.state.imageUrl && <img width="100%" src={this.state.imageUrl} />}
          </>
        )}
      </ApolloConsumer>
    );
  }
}

export default ImageUploader;
