import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import * as yup from 'yup';
import { ErrorMessage, SuccessMessage, SaveButton, withRoot } from 'b6a-components/dist';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import { GetUser, User } from './index';

const EDIT_USER = gql`
  mutation editUser($userId: String!, $userDetails: EditUserDetailsInput!) {
    editUserDetails(id: $userId, userDetails: $userDetails) {
      id
      email
      firstName
      lastName
      isAdmin
      created
      passwordChanged
      lastLogin
      verified
      clients {
        name
        id
        slug
      }
    }
  }
`;

interface EditUserDetailProps extends WithStyles<typeof styles> {
  user: User;
  handleClose: () => void;
}

interface EditUserMutationVariables {
  userId: string;
  userDetails: { firstName: string; lastName: string; email: string };
}

interface EditUserValues {
  firstName: string;
  lastName: string;
  email: string;
}

const styles = (theme: Theme) => ({
  buttonRow: {
    marginTop: theme.spacing.unit * 2,
  },
});

class EditUserDetailPanel extends React.Component<EditUserDetailProps> {
  validator = yup.object().shape({
    firstName: yup.string().required('First Name is Required'),
    lastName: yup.string().required('Last Name is Required'),
    email: yup
      .string()
      .email('Email must be a proper email')
      .required('Email is Required'),
  });

  onSubmit(addClient: (values: { variables: EditUserMutationVariables }) => void) {
    return (values: EditUserValues) => {
      addClient({
        variables: {
          userId: this.props.user.id,
          userDetails: {
            firstName: values.firstName,
            lastName: values.lastName,
            email: values.email,
          },
        },
      });
    };
  }

  renderForm(user: User, editUser: () => void, loading: boolean) {
    return (
      <Formik<EditUserValues>
        initialValues={user}
        validationSchema={this.validator}
        onSubmit={this.onSubmit(editUser)}
      >
        {({ errors, touched }) => {
          const isError = Object.keys(errors).length > 0;

          return (
            <Form>
              <DialogTitle id="form-dialog-title">Edit User</DialogTitle>

              <DialogContent>
                <Field
                  label="First Name"
                  type="text"
                  name="firstName"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />
                <Field
                  label="Last Name"
                  type="text"
                  name="lastName"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />

                <Field
                  label="Email"
                  type="email"
                  name="email"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />
              </DialogContent>

              <DialogActions>
                <Button onClick={this.props.handleClose} color="primary">
                  Cancel
                </Button>
                <SaveButton loading={loading} disabled={isError}>
                  Edit User
                </SaveButton>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    );
  }

  render() {
    return (
      <Mutation<GetUser, EditUserMutationVariables> mutation={EDIT_USER}>
        {(editUser, { loading, data, error }) => {
          const successMessage = (() => {
            if (!loading && !error && data) {
              return <SuccessMessage />;
            }
            return null;
          })();

          return (
            <>
              {successMessage}
              {error && (
                <ErrorMessage
                  header="Unable to Save"
                  message={`Error: ${error}`.replace('Error: GraphQL error: ', '')}
                />
              )}
              {this.renderForm(this.props.user, editUser, loading)}
            </>
          );
        }}
      </Mutation>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(EditUserDetailPanel));
