import Layout from '../components/Layout';
import React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { Button, Theme, WithStyles, withStyles } from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { TextField } from 'formik-material-ui';
import Grid from '@material-ui/core/Grid';
import { Panel, ErrorMessage, SuccessMessage, withRoot } from 'b6a-components/dist';
import Typography from '@material-ui/core/Typography';

const RESET_USER_PASSWORD = gql`
  mutation resetUserPassword($email: String!) {
    resetUserPassword(email: $email)
  }
`;

const styles = (theme: Theme) => ({
  buttonRow: {
    marginTop: theme.spacing.unit * 2,
  },
});

interface ResetUserPasswordMutation {
  resetUserPassword?: {
    id: string;
  };
}

interface ResetUserPasswordVariables {
  email: string;
}

interface ResetUserPasswordValues {
  email: string;
}

interface ResetUserPasswordProps extends WithStyles<typeof styles> {}

class ResetUserPasswordPage extends React.Component<ResetUserPasswordProps> {
  onSubmit(resetUserPassword: (values: { variables: ResetUserPasswordVariables }) => void) {
    return (values: ResetUserPasswordValues) => {
      resetUserPassword({
        variables: values,
      });
    };
  }

  renderForm(addClient: () => void) {
    const { classes } = this.props;

    return (
      <Formik<ResetUserPasswordValues>
        initialValues={{ email: '' }}
        onSubmit={this.onSubmit(addClient)}
      >
        {({ resetForm, errors }) => {
          const isError = Object.keys(errors).length > 0;

          return (
            <Form>
              <Field
                label="Email"
                type="email"
                name="email"
                fullWidth
                required
                autoComplete="off"
                component={TextField}
              />

              <div className={classes.buttonRow}>
                <Button color="primary" disabled={isError} type="submit" variant="contained">
                  Submit Password Reset
                </Button>
                <Button onClick={() => resetForm()}>Reset Form</Button>
              </div>
            </Form>
          );
        }}
      </Formik>
    );
  }

  render() {
    return (
      <Layout isAdmin={true}>
        <Grid container={true} justify="center">
          <Panel size={6} color="GREEN" icon="person_rounded" title="Reset Password">
            <Mutation<ResetUserPasswordMutation, ResetUserPasswordVariables>
              mutation={RESET_USER_PASSWORD}
            >
              {(addClient, { loading, data, error }) => {
                if (!loading && !error && data && data.resetUserPassword) {
                  return (
                    <SuccessMessage header="Success">
                      <Typography>
                        If you have submitted a valid email, you will receive an email shortly.
                      </Typography>
                    </SuccessMessage>
                  );
                }
                return (
                  <>
                    {error && <ErrorMessage header="Unable to Save" message={error.message} />}
                    {this.renderForm(addClient)}
                  </>
                );
              }}
            </Mutation>
          </Panel>
        </Grid>
      </Layout>
    );
  }
}

// @ts-ignore
export default withRoot(withStyles(styles)(ResetUserPasswordPage));
