import React, { Component } from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DialogProps } from '../..';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Theme,
  WithStyles,
  withStyles,
} from '@material-ui/core';
import { Query } from 'react-apollo';
import graphql from 'graphql-tag';
import { queryHandle } from 'b6a-components/dist';

const GET_CLIENT_PAGES = graphql`
  query getClientPages($client: String!) {
    client(slug: $client) {
      id
      pages {
        title
        route
      }
    }
  }
`;

interface ClientPagesResponse {
  client: {
    pages: {
      title: string;
      route: string;
    }[];
  };
}

interface HyperlinkDialogProps extends DialogProps {
  client: string;
}

interface EditPaperDialogValues {
  text: string;
  href: string;
  linkType: 'internal' | 'external';
}

const styles = (theme: Theme) => ({
  form: {
    width: 100,
    display: 'block',
  },
  formField: {
    width: '100%',
  },
});

class HyperlinkDialog extends Component<HyperlinkDialogProps & WithStyles<typeof styles>> {
  handleChange(
    initialRoute: string,
    handleChange: (e: React.ChangeEvent<any>) => void,
    setFieldValue: (field: string, value: any, shouldValidate?: boolean) => void
  ) {
    return (e: React.ChangeEvent<any>) => {
      if (e.target.value === 'internal') {
        setFieldValue('href', initialRoute, false);
      } else {
        setFieldValue('href', '', false);
      }

      handleChange(e);
    };
  }

  render() {
    const { onClose, editor, classes, client } = this.props;

    return (
      <Query<ClientPagesResponse> query={GET_CLIENT_PAGES} variables={{ client }}>
        {queryHandle(data => {
          if (!data) return null;

          const { pages } = data.client;

          return (
            <Formik<EditPaperDialogValues>
              initialValues={{ text: '', href: '/puma/dashboard', linkType: 'internal' }}
              onSubmit={values => {
                if (editor) {
                  editor
                    .insertText(values.text)
                    .moveFocusBackward(values.text.length)
                    .wrapInline({
                      type: 'link',
                      data: { href: values.href },
                    });
                }

                onClose('set-hyperlink');
              }}
            >
              {({ values, setFieldValue, handleChange }) => (
                <Form>
                  <DialogTitle>Add a hyperlink</DialogTitle>
                  <DialogContent>
                    <Field
                      label="Link Text"
                      type="text"
                      name="text"
                      fullWidth
                      required
                      autoComplete="off"
                      component={TextField}
                    />

                    <FormControl margin="normal" className={classes.form}>
                      <InputLabel htmlFor="linkType">Type</InputLabel>
                      <Field
                        name="linkType"
                        component={Select}
                        fullWidth
                        inputProps={{
                          name: 'linkType',
                          id: 'linkType',
                          onChange: this.handleChange(
                            `/${client}/${pages[0].route}`,
                            handleChange,
                            setFieldValue
                          ).bind(this),
                        }}
                      >
                        <MenuItem value="internal">Internal</MenuItem>
                        <MenuItem value="external">External</MenuItem>
                      </Field>
                    </FormControl>

                    {values.linkType === 'internal' && (
                      <FormControl margin="normal">
                        <InputLabel htmlFor="href">Internal Link</InputLabel>
                        <Field
                          name="href"
                          fullWidth
                          component={Select}
                          inputProps={{
                            name: 'href',
                            id: 'href',
                          }}
                        >
                          {pages.map(page => (
                            <MenuItem value={`/${client}/${page.route}`} key={page.route}>
                              {page.title}
                            </MenuItem>
                          ))}
                        </Field>
                      </FormControl>
                    )}

                    {values.linkType === 'external' && (
                      <Field
                        label="External Link"
                        type="text"
                        name="href"
                        fullWidth
                        required
                        autoComplete="off"
                        component={TextField}
                      />
                    )}
                  </DialogContent>

                  <DialogActions>
                    <Button
                      data-testid="dialogCancel"
                      onClick={() => this.props.onClose('cancel')}
                      color="primary"
                    >
                      Cancel
                    </Button>
                    <Button
                      data-testid="dialogAccept"
                      type="submit"
                      variant="contained"
                      color="primary"
                    >
                      Insert
                    </Button>
                  </DialogActions>
                </Form>
              )}
            </Formik>
          );
        })}
      </Query>
    );
  }
}

// @ts-ignore
export default withStyles(styles)(HyperlinkDialog);
