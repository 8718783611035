import React from 'react';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import { DialogProps } from '../..';
import { Field, Form, Formik } from 'formik';
import { Select, TextField } from 'formik-material-ui';
import graphql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import * as yup from 'yup';
import { ICONS } from '../../../../consts';
import { Theme, WithStyles, withStyles } from '@material-ui/core';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Icon from '@material-ui/core/Icon';
import FormControl from '@material-ui/core/FormControl';
import { ErrorMessage, SuccessMessage } from 'b6a-components/dist';

const EDIT_PAGE_TO_CLIENT = graphql`
  mutation editPageDetails($id: String!, $pageDetails: EditPageDetailsInput!) {
    editPageDetails(id: $id, pageDetails: $pageDetails) {
      id
      title
      route
    }
  }
`;

const styles = (theme: Theme) => ({
  buttonRow: {
    marginTop: theme.spacing.unit * 2,
  },
});

interface EditClientMutationResult {
  editPageDetails?: {
    route: string;
    id: string;
  };
}

interface EditClientMutationVariables {
  id: string;
  pageDetails: {
    title: string;
    menuBarTitle: string;
    menuBarIcon: string;
    route: string;
    inMenuBar: boolean;
  };
}

interface EditClientValues {
  title: string;
  menuBarTitle: string;
  menuBarIcon: string;
  route: string;
}

interface EditPageDialogProps extends WithStyles<typeof styles> {
  clientSlug: string;
  title: string;
  menuBarTitle: string;
  menuBarIcon: string;
  route: string;
  handleClose: () => void;
  pageId: string;
}

class EditPageDialog extends React.Component<EditPageDialogProps & DialogProps> {
  validator = yup.object().shape({
    route: yup
      .string()
      .label('Route')
      .min(5, 'Route must be 4 characters long')
      .strict(true)
      .matches(/^\//, 'Route must start with /')
      .lowercase()
      .required(),
    title: yup
      .string()
      .label('Page Title')
      .required(),
    menuBarTitle: yup
      .string()
      .label('Side Bar Title')
      .required(),
  });

  onSubmit(editClient: (values: { variables: EditClientMutationVariables }) => void) {
    const { pageId: id } = this.props;

    return (values: EditClientValues) => {
      editClient({
        variables: {
          id,
          pageDetails: {
            ...values,
            inMenuBar: true,
          },
        },
      });
    };
  }

  renderForm(editClient: () => void) {
    const { title, menuBarTitle, menuBarIcon, route } = this.props;

    return (
      <Formik<EditClientValues>
        initialValues={{ title, menuBarTitle, menuBarIcon, route: `/${route}` }}
        validationSchema={this.validator}
        onSubmit={this.onSubmit(editClient)}
      >
        {({ resetForm, errors }) => {
          const isError = Object.keys(errors).length > 0;

          return (
            <Form>
              <DialogTitle id="form-dialog-title">Edit Page to Client</DialogTitle>

              <DialogContent>
                <DialogContentText>Edit a new page.</DialogContentText>

                <Field
                  label="Page Title"
                  type="text"
                  name="title"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />
                <Field
                  label="Menu Bar Title"
                  type="text"
                  name="menuBarTitle"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />

                <Field
                  label="Route"
                  type="text"
                  name="route"
                  fullWidth
                  required
                  autoComplete="off"
                  component={TextField}
                />

                <FormControl>
                  <InputLabel htmlFor="icon">Icon</InputLabel>
                  <Field
                    name="menuBarIcon"
                    component={Select}
                    fullWidth
                    inputProps={{
                      name: 'menuBarIcon',
                      id: 'menuBarIcon',
                    }}
                  >
                    {ICONS.map(v => (
                      <MenuItem value={v} key={`icons-${v}`}>
                        <Icon>{v}</Icon>
                      </MenuItem>
                    ))}
                  </Field>
                </FormControl>
              </DialogContent>

              <DialogActions>
                <Button
                  data-testid="dialogCancel"
                  onClick={() => this.props.onClose('cancel')}
                  color="primary"
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  disabled={isError}
                  data-testid="dialogAccept"
                  type="submit"
                  color="primary"
                >
                  Submit
                </Button>
              </DialogActions>
            </Form>
          );
        }}
      </Formik>
    );
  }

  render() {
    return (
      <Mutation<EditClientMutationResult, EditClientMutationVariables>
        mutation={EDIT_PAGE_TO_CLIENT}
      >
        {(editPage, { loading, data, error }) => {
          const successMessage = (() => {
            if (!loading && !error && data && data.editPageDetails) {
              return <SuccessMessage />;
            }
            return null;
          })();

          return (
            <>
              {successMessage}
              {error && (
                <ErrorMessage
                  header="Unable to Save"
                  message={`Error: ${error}`.replace('Error: GraphQL error: ', '')}
                />
              )}
              {this.renderForm(editPage)}
            </>
          );
        }}
      </Mutation>
    );
  }
}

// @ts-ignore
export default withStyles(styles)(EditPageDialog);
