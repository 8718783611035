import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import React from 'react';
import { Theme, withStyles, WithStyles } from '@material-ui/core';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import { SuccessMessage, SaveButton, withRoot } from 'b6a-components/dist';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';

const SET_USER_TO_CLIENT = gql`
  mutation setUserToClient($slug: String!, $userId: String!) {
    setUserToClient(slug: $slug, userId: $userId) {
      id
      users {
        id
        email
        firstName
        lastName
      }
    }
  }
`;

const styles = (theme: Theme) => ({});

interface AssignUserDialogProps extends WithStyles<typeof styles> {
  clientSlug: string;
  clientName: string;
  handleClose: () => void;
  users: {
    id: string;
    email: string;
    lastName: string;
    firstName: string;
  }[];
}

interface AssignUserDialogState {
  selectedUser: string;
}

class AssignUserDialog extends React.Component<AssignUserDialogProps, AssignUserDialogState> {
  state = {
    selectedUser: '',
  };

  handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
    this.setState({ selectedUser: event.target.value });
  };

  render() {
    const { clientSlug, users, clientName } = this.props;

    users.sort((a, b) => {
      if (a.lastName === b.lastName) {
        return a.firstName > b.firstName ? 1 : -1;
      }
      return a.lastName > b.lastName ? 1 : -1;
    });

    return (
      <Mutation mutation={SET_USER_TO_CLIENT}>
        {(submit, { data, loading }) => (
          <>
            <DialogTitle id="form-dialog-title">Assign User to Client</DialogTitle>

            <DialogContent>
              <DialogContentText>
                Select the user to add to: {clientName}
                {data && <SuccessMessage message="User Added Successfully" />}
              </DialogContentText>

              <Select
                value={this.state.selectedUser}
                onChange={this.handleChange}
                name="age"
                fullWidth
                inputProps={{
                  id: 'email-select',
                }}
              >
                {users.map(v => (
                  <MenuItem key={v.id} value={v.id}>
                    {v.lastName}, {v.firstName} {'<'}
                    {v.email}
                    {'>'}
                  </MenuItem>
                ))}
              </Select>
            </DialogContent>

            <DialogActions>
              <Button onClick={this.props.handleClose} color="primary">
                Cancel
              </Button>
              <SaveButton
                loading={loading}
                onClick={() => {
                  submit({ variables: { slug: clientSlug, userId: this.state.selectedUser } });
                }}
              >
                Assign User
              </SaveButton>
            </DialogActions>
          </>
        )}
      </Mutation>
    );
  }
}

export default withRoot(withStyles(styles)(AssignUserDialog));
